import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../../hook/usePost";
import Notification from "../../notification";

export default function AddMaterial() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [material, setMaterial] = useState({});

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/materials";

  const { loading, error, confirmation, post } = usePost(url);

  const categories = [
    "Mechanical",
    "Electromechanical",
    "Electrical",
    "Others",
  ];

  // Effect to calculate total_price whenever quantity or unit_price changes
  useEffect(() => {
    const total_price = material.quantity * material.unit_price;
    material.quantity &&
      material.unit_price &&
      setValue("total_price", total_price);
  }, [material.quantity, material.unit_price, setValue]);

  const OnSubmit = (material) => {
    material.expiration_date = dayjs(material.expiration_date).format(
      "YYYY-MM-DD"
    );
    material.date_received = dayjs(material.date_received).format("YYYY-MM-DD");
    material.data_encoder = sessionStorage.getItem("user_name");

    post(material, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setMaterial({ ...material, [field]: value });
    setValue(field, value);
  };

  const handleDateChange = (field) => (date) => {
    setMaterial({ ...material, [field]: date });
    setValue(field, date);
  };

  return (
    <Box sx={{ width: "min(1000px, 100%)" }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            Add material
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="material_name"
              control={control}
              defaultValue=""
              rules={{ required: "Material name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("material_name")}
                    error={Boolean(errors.material_name)}
                  />
                  {errors.material_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.material_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="material_category"
              control={control}
              defaultValue=""
              rules={{ required: "Material category is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("material_category")}
                    error={Boolean(errors.material_category)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.material_category && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.material_category.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="brand"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Brand</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("brand")}
                    error={Boolean(errors.brand)}
                  />
                  {errors.brand && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.brand.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="material_description"
              control={control}
              defaultValue=""
              rules={{ required: "Material description is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material description <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("material_description")}
                    error={Boolean(errors.material_description)}
                  />
                  {errors.material_description && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.material_description.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{ required: "Quantity is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="measuring_unit"
              control={control}
              defaultValue=""
              rules={{ required: "Measuring unit is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Measuring unit <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("measuring_unit")}
                    error={Boolean(errors.measuring_unit)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {[
                      "Number",
                      "Meter",
                      "Liter",
                      "Pieces",
                      "Package",
                      "Kg",
                    ].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.measuring_unit && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.measuring_unit.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="unit_price"
              control={control}
              defaultValue=""
              rules={{ required: "Unit price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Unit price <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("unit_price")}
                    error={Boolean(errors.unit_price)}
                  />
                  {errors.unit_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.unit_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="total_price"
              control={control}
              defaultValue=""
              rules={{ required: "Total price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Total price <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.total_price)}
                  />
                  {errors.total_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.total_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="selling_price"
              control={control}
              defaultValue=""
              rules={{ required: "Selling price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Selling price <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("selling_price")}
                    error={Boolean(errors.selling_price)}
                  />
                  {errors.selling_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.selling_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="supplier"
              control={control}
              defaultValue=""
              rules={{ required: "Supplier is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Supplier <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier")}
                    error={Boolean(errors.supplier)}
                  />
                  {errors.supplier && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.supplier.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="supplier_contact"
              control={control}
              defaultValue=""
              rules={{ required: "Supplier contact is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Supplier contact <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier_contact")}
                    error={Boolean(errors.supplier_contact)}
                  />
                  {errors.supplier_contact && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.supplier_contact.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="expiration_date"
                control={control}
                defaultValue={null}
                rules={{ required: "Expiration date is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Date expired
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("expiration_date")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.expiration_date)}
                    />
                    {errors.expiration_date && (
                      <p
                        style={{ color: "red", fontSize: "0.8rem", margin: 0 }}
                      >
                        {errors.expiration_date.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
