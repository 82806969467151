import React, { useState, useEffect } from "react";
import { Paper, Box, Stack, Typography } from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BackpackOutlinedIcon from "@mui/icons-material/BackpackOutlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";

import RecentSales from "./recentSales";
import useFetch from "../../hook/useFetch";

export default function Dashboard({
  date_start = "2000-01-01",
  date_end = "2500-01-01",
  handleDateSelector,
}) {
  const [open, setOpen] = React.useState(
    window.innerWidth > 1100 ? true : false
  );
  const [products, setProducts] = useState([]);
  const [sales, setSales] = useState([]);
  const [reorders, setReorders] = useState([]);

  console.log(date_start);
  const productUrl = `https://gp.maflink.com/api/api/products?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const salesUrl = `https://gp.maflink.com/api/api/sales?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const reordersUrl = `https://gp.maflink.com/api/api/orders?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const [productData, fetchProduct] = useFetch(productUrl);
  const [salesData, fetchSales] = useFetch(salesUrl);
  const [reordersData, fetchReorders] = useFetch(reordersUrl);

  useEffect(() => {
    productData && setProducts(productData);
    salesData && setSales(salesData);
    reordersData && setReorders(reordersData);
  }, [productData, salesData, reordersData]);

  /*Call function to display date selector on admin nav bar, 
  handleDateSelector() function is passed as parameter from admin page*/
  handleDateSelector();

  return (
    <Stack sx={{ width: "100%", p: 1 }}>
      <Box
        sx={{
          width: "min(1000px,100%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexFlow: "row nowrap",
          overflowX: "scroll",
          gap: 3,
          pb: 3,
        }}
      >
        <Paper
          sx={{
            width: 320,
            height: 200,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexFlow: "row nowrap",
              gap: 5,
            }}
          >
            <InventoryOutlinedIcon
              sx={{ color: "#0030b8", fontSize: "4.5em" }}
            />
            <Box textAlign="center">
              <Typography whiteSpace="nowrap">Total purchase</Typography>
              <Typography variant="h5">
                {new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(
                  products.reduce(
                    (acc, product) => acc + parseFloat(product.total_price),
                    0
                  )
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "0.9em", whiteSpace: "nowrap" }}
          >{`Last updated at ${
            products && products[1]?.updated_at
              ? new Date(products[1].updated_at).toLocaleString()
              : "N/A"
          }`}</Typography>
        </Paper>
        <Paper
          sx={{
            width: 320,
            height: 200,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexFlow: "row nowrap",
              gap: 5,
            }}
          >
            <MonetizationOnIcon sx={{ color: "#0030b8", fontSize: "4.5em" }} />
            <Box textAlign="center">
              <Typography whiteSpace="nowrap">Total sales</Typography>
              <Typography variant="h5">
                {new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(
                  sales.reduce(
                    (acc, sale) => acc + parseFloat(sale.total_price),
                    0
                  )
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "0.9em", whiteSpace: "nowrap" }}
          >{`Last updated at ${
            sales && sales[1]?.updated_at
              ? new Date(sales[1].updated_at).toLocaleString()
              : "N/A"
          }`}</Typography>
        </Paper>
        <Paper
          sx={{
            width: 320,
            height: 200,
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexFlow: "row nowrap",
              gap: 5,
            }}
          >
            <BackpackOutlinedIcon
              sx={{ color: "#0030b8", fontSize: "4.5em" }}
            />
            <Box textAlign="center">
              <Typography whiteSpace="nowrap">Total reorders</Typography>
              <Typography variant="h5">
                {new Intl.NumberFormat("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(
                  reorders.reduce(
                    (acc, reorders) => acc + parseFloat(reorders.total_price),
                    0
                  )
                )}
              </Typography>
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "0.9em", whiteSpace: "nowrap" }}
          >{`Last updated at ${
            reorders && reorders[0]?.updated_at
              ? new Date(reorders[0].updated_at).toLocaleString()
              : "N/A"
          }`}</Typography>
        </Paper>
      </Box>
      <RecentSales />
    </Stack>
  );
}
