import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import ExportToPdf from "../exportToPdf";

function ReceiptBody({ Comp }) {
  return (
    <Box sx={{ p: 5 }}>
      <Stack>
        <img
          style={{
            width: 300,
            height: "auto",
            backgroundColor: "white",
            borderRadius: 3,
          }}
          src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
          alt="Company Logo"
        />
      </Stack>
      <Stack alignItems="center">
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText
            primary="Invoice no:"
            secondary={` ${Comp.invoice_number}`}
          />
          <ListItemText primary="TIN:" secondary={Comp.tin} />
        </List>
        <Divider
          sx={{
            width: "100%",
            borderColor: "gray",
            mb: 3,
          }}
        />
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText
            primary="Name of customer:"
            secondary={Comp.customer_name}
          />
          <ListItemText primary="Contact:" secondary={Comp.customer_contact} />
        </List>
        <Divider
          sx={{
            width: "100%",
            mb: 3,
          }}
        />
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-evenly",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText primary="Product name:" secondary={Comp.product_name} />
          <ListItemText primary="Product Id:" secondary={Comp.product_id} />
          <ListItemText primary="" secondary="" sx={{ flexGrow: 2 }} />
        </List>
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText primary="Quantity:" secondary={Comp.quantity} />
          <ListItemText primary="Unit price:" secondary={Comp.unit_price} />
          <ListItemText primary="Total price:" secondary={Comp.total_price} />
        </List>
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText primary="Tax:" secondary={Comp.tax} />
          <ListItemText
            primary="Withholding. tax:"
            secondary={Comp.withholding_tax}
          />
        </List>
        <Divider
          sx={{
            width: "100%",
            mb: 3,
          }}
        />
        <List
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            width: "100%",
            gap: 5,
          }}
        >
          <ListItemText
            primary="Payment method"
            secondary={Comp.payment_method}
          />
          <ListItemText
            primary="Accountant Name and Sign:"
            secondary="------------------------------------"
          />
        </List>
      </Stack>
    </Box>
  );
}

export default function Receipt({ data }) {
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(data);
  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <ReceiptBody Comp={data} />
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ color: "#383315" }} onClick={handleClose}>
            Cancel
          </Button>
          <ExportToPdf
            Comp={(props) => <ReceiptBody {...props} Comp={data} />}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
