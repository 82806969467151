import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../../hook/usePost";
import useFetch from "../../../hook/useFetch";
import Notification from "../../notification";

export default function DispatchMaterial() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rows, setRows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [material, setMaterial] = useState({ reason: "Dispatch" });

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/material-transactions";

  const projectsUrl = `https://gp.maflink.com/api/api/projects?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}`;

  const materialUrl = `https://gp.maflink.com/api/api/materials?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}&location=${encodeURIComponent(null)}`;

  const { loading, error, confirmation, post } = usePost(url);

  const [projectData, fetchProject] = useFetch(projectsUrl);
  const [materialData, fetchMaterial] = useFetch(materialUrl);

  useEffect(() => {
    materialData && setRows(materialData);
    projectData && setProjects(projectData.data);
  }, [materialData, projectData]);
  console.log(material);

  const MaterialName = rows.filter(
    (row) => row.material_name === material.material_name
  );

  useEffect(() => {
    if (material) {
      setMaterial({
        ...material,
        material_category: MaterialName[0]
          ? MaterialName[0].material_category
          : "",
        brand: MaterialName[0] ? MaterialName[0].brand : "",
        material_description: MaterialName[0]
          ? MaterialName[0].material_description
          : "",
        measuring_unit: MaterialName[0] ? MaterialName[0].measuring_unit : "",
        unit_price: MaterialName[0] ? MaterialName[0].unit_price : "",
        total_price: MaterialName[0] ? MaterialName[0].total_price : "",
        selling_price: MaterialName[0] ? MaterialName[0].selling_price : "",
        supplier: MaterialName[0] ? MaterialName[0].supplier : "",
        supplier_contact: MaterialName[0]
          ? MaterialName[0].supplier_contact
          : "",
        date_expired: MaterialName[0] ? MaterialName[0].date_expired : "",
      });

      setValue(
        "material_category",
        MaterialName[0] ? MaterialName[0].material_category : ""
      );
      setValue("brand", MaterialName[0] ? MaterialName[0].brand : "");
      setValue(
        "material_description",
        MaterialName[0] ? MaterialName[0].material_description : ""
      );
      setValue(
        "measuring_unit",
        MaterialName[0] ? MaterialName[0].measuring_unit : ""
      );
      setValue("unit_price", MaterialName[0] ? MaterialName[0].unit_price : "");
      setValue(
        "total_price",
        MaterialName[0] ? MaterialName[0].total_price : ""
      );
      setValue(
        "selling_price",
        MaterialName[0] ? MaterialName[0].selling_price : ""
      );
      setValue("supplier", MaterialName[0] ? MaterialName[0].supplier : "");
      setValue(
        "supplier_contact",
        MaterialName[0] ? MaterialName[0].supplier_contact : ""
      );
      setValue(
        "date_expired",
        MaterialName[0] ? MaterialName[0].date_expired : ""
      );
    }
  }, [
    material.material_category,
    material.material_description,
    material.measuring_unit,
    material.unit_price,
    material.total_price,
    material.selling_price,
    material.supplier,
    material.supplier_contact,
    material.date_expired,
    rows,
    setValue,
  ]);

  const OnSubmit = (material) => {
    material.date_expired = dayjs(material.date_expired).format("YYYY-MM-DD");
    material.data_encoder = sessionStorage.getItem("user_name");
    post(material, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setMaterial({ ...material, [field]: value });
    setValue(field, value);
  };

  return (
    <Box sx={{ width: "min(1000px, 100%)" }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <PinDropIcon />
          <Typography color="inherit" variant="h6">
            Dispatch material
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="material_name"
              control={control}
              defaultValue=""
              rules={{ required: "Material name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Material name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("material_name")}
                    error={Boolean(errors.material_name)}
                  >
                    {rows.map((row) => (
                      <MenuItem
                        key={row.material_name}
                        value={row.material_name}
                      >
                        {row.material_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.material_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.material_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{
                required: "Quantity is required",
                min: {
                  value: 1,
                  message: `Minimum quantity to dispatch is 1`,
                },

                max: {
                  value: MaterialName[0] ? MaterialName[0].quantity : Infinity,
                  message:
                    MaterialName[0] && MaterialName[0].quantity > 0
                      ? `Maximum available quantity is ${MaterialName[0].quantity}`
                      : `No  enough material`,
                },
              }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="measuring_unit"
              control={control}
              defaultValue=""
              rules={{ required: "Measuring unit is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Measuring unit <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("asigned_to")}
                    error={Boolean(errors.measuring_unit)}
                  />
                  {errors.measuring_unit && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.measuring_unit.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: "Location is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Location <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("location")}
                    error={Boolean(errors.location)}
                  >
                    {projects.map((project) => (
                      <MenuItem
                        key={project.project_name}
                        value={project.project_name}
                      >
                        {project.project_name}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other">Other service</MenuItem>
                  </Select>
                  {errors.location && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.location.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="asigned_to"
              control={control}
              defaultValue=""
              rules={{ required: "Asigned to is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Asigned to <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("asigned_to")}
                    error={Boolean(errors.asigned_to)}
                  />
                  {errors.asigned_to && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.asigned_to.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
