import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import AppsIcon from "@mui/icons-material/Apps";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import { Button, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import useUpdate from "../../../hook/useUpdate";
import ProductChange from "../productChange";
import useFetch from "../../../hook/useFetch";
import ExportToExcel from "../../exportToExcel";

const EditableCell = ({ row, edit, field, align, onBlur }) => (
  <TableCell
    onClick={(e) =>
      row.id === edit
        ? (e.target.contentEditable = true)
        : (e.target.contentEditable = false)
    }
    onBlur={(e) => onBlur(e.target.textContent)}
    align={align}
    sx={{ whiteSpace: "nowrap" }}
  >
    {row[field]}
  </TableCell>
);

//Custom styling
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.45),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "31ch",
      },
    },
  },
}));

//Component for table head
function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
  return (
    <TableHead
      sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {[
          "No",
          "Transaction id",
          "Product Name",
          "Product Id",
          "Category",
          "Product description",
          "Quantity",
          "Unit Price",
          "Total Price",
          "Selling price",
          "Supplier",
          "Supplier Contact",
          "Date Received",
          "Last Updated",
          "Expiration Date",
          "Batch Number",
          "Condition",
          "Serial Number",
          "Data encoder",
          "Notes",
        ].map((headCell) => (
          <TableCell
            key={headCell}
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//Dropdown view component
function Dropdown({ handleSearch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
          ml: -1,
        }}
      >
        <AppsIcon sx={{ cursor: "pointer" }} />

        <Typography sx={{ color: "black" }}>Transactions | Products</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleSearch("delete");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted transactions</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("expired");
            handleClose();
          }}
        >
          <ListItemIcon>
            <LockClockOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Expired transactions</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

//Mobile dropdown view component
function MobileDropdown({ handleSearch, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { lg: "none", md: "none", sm: "flex", xs: "flex" } }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "#3f3f3fd6",
        }}
      >
        <DragIndicatorIcon sx={{ cursor: "pointer" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem sx={{ ml: -3, p: 3 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search product"
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </Search>
        </MenuItem>
        <MenuItem sx={{ ml: -0.5 }}>
          <ExportToExcel data={data} filename="purchase-transactions.xlsx" />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleSearch("delete");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted products</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSearch("expired");
            handleClose();
          }}
        >
          <ListItemIcon>
            <LockClockOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Expired products</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography>Filter</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

//Component for table toolbar
function EnhancedTableToolbar({ handleSearch, rows, handleDateFilter }) {
  // Common styles
  const toolbarStyles = {
    width: "100%",
    display: "flex",
    alignContent: "center",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #235af3",
    position: "sticky",
    left: 0,
    p: 2,
  };

  const stackStyles = {
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 3,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Stack sx={stackStyles}>
        <Dropdown handleSearch={handleSearch} />
      </Stack>
      <Stack
        sx={{
          display: { md: "flex", sm: "none", xs: "none" },
          flexFlow: "row wrap",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Name,encoder,batch, inv. no, brand"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Search>
        <DateFilter handleDateFilter={handleDateFilter} />
        <ExportToExcel data={rows} filename="purchase-transactions.xlsx" />
      </Stack>
      <MobileDropdown handleSearch={handleSearch} data={rows} />
    </Stack>
  );
}

//Collective component to be exported
export default function PurchaseTransaction() {
  const tableContainerHeight = window.innerHeight;
  const [selected, setSelected] = useState([]);
  const [edit, setEdit] = useState(0);
  const [id, setId] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [serial_number, setSerialNumber] = useState("");
  const [availability, setAvailability] = useState("");
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);
  const [delete_confirmation, setDeleteConfirmation] = useState(false);
  const [rows, setRows] = useState([]);
  const [product, setProduct] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/purchase-transactions/" + edit;

  const fetchUrl = `https://gp.maflink.com/api/api/purchase-transactions?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const { loading, error, confirmation, update } = useUpdate(url);

  const [data, fetchData] = useFetch(fetchUrl);

  const permit = JSON.parse(sessionStorage.getItem("permissions"));

  useEffect(() => {
    data && setRows(data.data);
  }, [data]);

  const handleDelete = (id, product_name, serial_number, availability) => {
    setDeleteConfirmation(!delete_confirmation);
    setId(id);
    setEdit(id);
    setSelectItem(product_name);
    setSerialNumber(serial_number);
    setAvailability("delete");
  };

  const handleRestore = (id, product_name, serial_number, availability) => {
    setDeleteConfirmation(!delete_confirmation);
    setId(id);
    setEdit(id);
    setSelectItem(product_name);
    setSerialNumber(serial_number);
    setAvailability("available");
  };

  const handleDateFilter = (date_start, date_end) => {
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : null;

    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  function handleSearch(s) {
    setSearch(s);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (no) => selected.indexOf(no) !== -1;

  return (
    <Paper sx={{ width: "100%" }}>
      {delete_confirmation && (
        <ProductChange
          id={id}
          product_name={selectItem}
          serial_number={serial_number}
          availability={availability}
          url={url}
        />
      )}
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        rows={rows}
        handleDateFilter={handleDateFilter}
      />
      <TableContainer
        sx={{
          height: `calc(${tableContainerHeight}px - 240px)`,
          overflowY: "scroll",
        }}
      >
        <Table
          sx={{ minWidth: 750, overflowY: "scroll" }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover={row.availability === "available"}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor:
                      row.availability === "available" && edit && "pointer",
                    backgroundColor:
                      row.availability !== "available" && "#ffebc1",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="transaction_id"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, transaction_id: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_name"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_name: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_id"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_id: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_category"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_category: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_description"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_description: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="quantity"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, quantity: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="unit_price"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, unit_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="total_price"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, total_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="selling_price"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, selling_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="supplier"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, supplier: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="supplier_contact"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, supplier_contact: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="date_received"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, date_received: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="last_updated"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, last_updated: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="date_expired"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, date_expired: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="batch_number"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, batch_number: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="condition"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, condition: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="serial_number"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, serial_number: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="data_encoder"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, data_encoder: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="notes"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, notes: content })
                    }
                  />
                  <TableCell
                    align="left"
                    sx={{ whiteSpace: "nowrap" }}
                  ></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Typography sx={{ m: 3 }}>No product found</Typography>
        )}
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 3,
          p: 2,
        }}
      >
        <Button
          disabled={data && data.current_page === 1}
          onClick={(e) => handlePageChange(e, page - 1)}
          sx={{
            textTransform: "none",
            color: data && data.current_page === 1 ? "grey" : "#0030b8",
          }}
        >
          <ArrowBackIosNewIcon /> Back
        </Button>
        {data && data.current_page}
        <Divider orientation="vertical" flexItem />
        {data && data.last_page}
        <Button
          disabled={data && data.last_page === data.current_page}
          onClick={(e) => handlePageChange(e, page + 1)}
          sx={{
            textTransform: "none",
            color:
              data && data.last_page === data.current_page ? "grey" : "#0030b8",
          }}
        >
          Next <ArrowForwardIosIcon />
        </Button>
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data && data.current_page !== page}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Updating ...
          </Typography>
        </Box>
      </Backdrop>
    </Paper>
  );
}
