import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AppsIcon from "@mui/icons-material/Apps";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import { Button, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useUpdate from "../../hook/useUpdate";
import useFetch from "../../hook/useFetch";
import ExportToExcel from "../exportToExcel";
import ProjectChange from "./projectChange";
import PreviewIssue from "./previewIssue";

const EditableCell = ({ row, edit, field, align, onBlur }) => (
  <TableCell
    onClick={(e) =>
      row.id === edit
        ? (e.target.contentEditable = true)
        : (e.target.contentEditable = false)
    }
    onBlur={(e) => onBlur(e.target.textContent)}
    align={align}
    sx={{
      maxWidth: 200,
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    }}
  >
    {row[field]}
  </TableCell>
);

//Custom styling
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.45),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "31ch",
      },
    },
  },
}));

//Component for table head
function EnhancedTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
  date_start,
  date_end,
}) {
  return (
    <TableHead
      sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {[
          "No",
          "Data encoder",
          "Project Name",
          "Project Id",
          "Project owner",
          "Project consultant",
          "Date of start",
          "Estimated completion date",
          "Date of end",
          "Last Updated",
          "Project manager",
          "Status",
          "Project Issues",
          "Activities",
          "Budget",
          "Location",
          "Notes",
          "Actions",
        ].map((headCell) => (
          <TableCell
            key={headCell}
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//Dropdown view component
function Dropdown({ handleSearch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
          ml: -1,
        }}
      >
        <AppsIcon sx={{ cursor: "pointer" }} />
        <Typography sx={{ color: "black" }}>Projects</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleSearch("Active");
            handleClose();
          }}
        >
          <ListItemIcon>
            <BoltOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Active projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Terminated");
            handleClose();
          }}
        >
          <ListItemIcon>
            <NotInterestedOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Terminated projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Completed");
            handleClose();
          }}
        >
          <ListItemIcon>
            <CheckCircleOutlineOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Completed projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Deleted");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted projects</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

//Mobile dropdown view component
function MobileDropdown({ handleSearch, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { lg: "none", md: "none", sm: "flex", xs: "flex" } }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "#3f3f3fd6",
        }}
      >
        <DragIndicatorIcon sx={{ cursor: "pointer" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem sx={{ ml: -3, p: 3 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search project"
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </Search>
        </MenuItem>
        <MenuItem sx={{ ml: -0.5 }}>
          <ExportToExcel data={data} filename="projects.xlsx" />
        </MenuItem>
        <MenuItem
          component="a"
          href="/project-management/new-project"
          sx={{
            "&:hover": {
              backgroundColor: "blue",
              cursor: "default",
            },
            width: "100%",
            backgroundColor: "#0030b8",
            textTransform: "none",
            color: "white",
            p: 1,
          }}
          onClick={handleClose}
        >
          <ListItemIcon>
            <AddIcon fontSize="medium" sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1, whiteSpace: "nowrap" }}>
            New project
          </ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={(e) => {
            handleSearch("Active");
            handleClose();
          }}
        >
          <ListItemIcon>
            <BoltOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Active projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Terminated");
            handleClose();
          }}
        >
          <ListItemIcon>
            <NotInterestedOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Terminated projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Completed");
            handleClose();
          }}
        >
          <ListItemIcon>
            <CheckCircleOutlineOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Completed projects</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Deleted");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted projects</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography>Filter</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

//Component for table toolbar
function EnhancedTableToolbar({ handleSearch, rows, handleDateFilter }) {
  // Common styles
  const toolbarStyles = {
    width: "100%",
    display: "flex",
    alignContent: "center",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #235af3",
    position: "sticky",
    left: 0,
    p: 2,
  };

  const stackStyles = {
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 3,
  };

  const buttonStyles = {
    width: 160,
    backgroundColor: "#0030b8",
    textTransform: "none",
    color: "white",
    p: 1,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Stack sx={stackStyles}>
        <Dropdown handleSearch={handleSearch} />
      </Stack>
      <Stack
        sx={{
          display: { md: "flex", sm: "none", xs: "none" },
          flexFlow: "row wrap",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Project name, id, location"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Search>
        <DateFilter handleDateFilter={handleDateFilter} />
        <ExportToExcel data={rows} filename="projects.xlsx" />
        <Box sx={{ backgroundColor: "#235af3", borderRadius: 1 }}>
          <Button href="/project-management/new-project" sx={buttonStyles}>
            <AddIcon />
            <Typography sx={{ ml: 1, whiteSpace: "nowrap" }}>
              New project
            </Typography>
          </Button>
        </Box>
      </Stack>
      <MobileDropdown handleSearch={handleSearch} data={rows} />
    </Stack>
  );
}

//Collective component to be exported
export default function Projects() {
  const tableContainerHeight = window.innerHeight;
  const [selected, setSelected] = useState([]);
  const [edit, setEdit] = useState(0);
  const [id, setId] = useState("");
  const [issue, setIssue] = useState(false);
  const [selectItem, setSelectItem] = useState("");
  const [status, setStatus] = useState("");
  const [delete_confirmation, setDeleteConfirmation] = useState(false);
  const [rows, setRows] = useState([]);
  const [project, setProject] = useState({});
  const [project_id, setProjectId] = useState("");
  const [selectProject, setSelectProject] = useState("");
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);
  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/projects/" + edit;

  const fetchUrl = `https://gp.maflink.com/api/api/projects?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const { loading, error, confirmation, update } = useUpdate(url);

  const [data, fetchData] = useFetch(fetchUrl);

  const permit = JSON.parse(sessionStorage.getItem("permissions"));

  useEffect(() => {
    data && setRows(data.data);
  }, [data]);

  function handleIssue(bool, projectName) {
    setSelectProject(projectName);
    setIssue(bool);
  }

  const handleDateFilter = (date_start, date_end) => {
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : null;

    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  const handleDelete = (id, project_name, project_id, action) => {
    setDeleteConfirmation(!delete_confirmation);
    setId(id);
    setEdit(id);
    setSelectItem(project_name);
    setProjectId(project_id);
    setStatus(action);
  };

  function handleSearch(s) {
    setSearch(s);
  }

  function handleEdit(v) {
    setEdit(v);
  }

  async function handleUpdate() {
    await update(project, token);

    confirmation && alert(confirmation.message);
    setEdit(0);
    fetchData();
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (no) => selected.indexOf(no) !== -1;

  return (
    <Paper sx={{ width: "100%" }}>
      {delete_confirmation && (
        <ProjectChange
          project_name={selectItem}
          project_id={project_id}
          status={status}
          url={url}
          handleEdit={handleEdit}
        />
      )}
      {issue && (
        <PreviewIssue handleIssue={handleIssue} projectName={selectProject} />
      )}
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        rows={rows}
        handleDateFilter={handleDateFilter}
      />
      <TableContainer
        sx={{
          height: `calc(${tableContainerHeight}px - 240px)`,
          overflowY: "scroll",
        }}
      >
        <Table
          sx={{ minWidth: 750, overflowY: "scroll" }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            date_start={date_start}
            date_end={date_end}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover={row.status === "Active"}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor: row.status === "Active" && edit && "pointer",
                    backgroundColor: row.status === "delete" && "#ffebc1",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="data_encoder"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, data_encoder: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="project_name"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, project_name: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="project_id"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, project_id: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="project_owner"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, project_owner: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="project_consultant"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, project_consultant: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="date_of_start"
                    align="center"
                    onBlur={(content) =>
                      setProject({ ...project, date_of_start: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="estimated_completion_date"
                    align="center"
                    onBlur={(content) =>
                      setProject({
                        ...project,
                        estimated_completion_date: content,
                      })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="date_of_end"
                    align="center"
                    onBlur={(content) =>
                      setProject({ ...project, date_of_end: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    field="updated_at"
                    align="center"
                    onBlur={(content) =>
                      setProject({ ...project, updated_at: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="project_manager"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, project_manager: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="status"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, status: content })
                    }
                  />
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexFlow: "row nowrap",
                        cursor: "pointer",
                        textTransform: "none",
                        gap: 1,
                        backgroundColor: "#0030b8",
                      }}
                      onClick={() => handleIssue(true, row.project_name)}
                    >
                      <PreviewOutlinedIcon />
                      <Typography>Preview</Typography>
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexFlow: "row nowrap",
                        cursor: "pointer",
                        textTransform: "none",
                        gap: 1,
                        backgroundColor: "#0030b8",
                      }}
                      onClick={() => handleIssue(true, row.project_name)}
                    >
                      <PreviewOutlinedIcon />
                      <Typography>Preview</Typography>
                    </Button>
                  </TableCell>
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="budget"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, budget: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="location"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, location: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="notes"
                    align="left"
                    onBlur={(content) =>
                      setProject({ ...project, notes: content })
                    }
                  />
                  <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                    {!date_start && !date_end && (
                      <ButtonGroup sx={{ width: 210 }}>
                        {row.status === "Active" && (
                          <Box
                            sx={{
                              width: 100,
                              pt: 0.5,
                              backgroundColor:
                                row.id !== edit ? "#0436c3" : "#235af3",
                              borderRadius: "3px 0px 0px 3px",
                            }}
                          >
                            {row.id !== edit ? (
                              <Button
                                disabled={
                                  !permit.update_row || row.status !== "Active"
                                }
                                size="small"
                                variant="text"
                                sx={{
                                  width: 100,
                                  color: "white",
                                  textTransform: "none",
                                }}
                                onClick={() => handleEdit(row.id)}
                              >
                                <Tooltip title="Click to edit">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <EditNoteIcon />
                                    <Typography> Edit</Typography>
                                  </Box>
                                </Tooltip>
                              </Button>
                            ) : (
                              <Button
                                size="small"
                                variant="text"
                                sx={{
                                  width: 100,
                                  color: "white",
                                  textTransform: "none",
                                }}
                                onClick={handleUpdate}
                              >
                                <Tooltip title="Click to save">
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: 1,
                                    }}
                                  >
                                    <SaveOutlinedIcon />
                                    <Typography> Save</Typography>
                                  </Box>
                                </Tooltip>
                              </Button>
                            )}
                          </Box>
                        )}

                        {row.status === "Active" ? (
                          <Box>
                            <FormControl
                              size="small"
                              sx={{
                                width: "16ch",
                                backgroundColor: "red",
                                borderRadius: "0px 3px 3px 0px",
                              }}
                            >
                              <Select
                                disabled={!permit.update_row}
                                value={row.status}
                                onChange={(event) =>
                                  handleDelete(
                                    row.id,
                                    row.project_name,
                                    row.project_id,
                                    event.target.value
                                  )
                                }
                                sx={{ color: "white" }}
                              >
                                <MenuItem value={row.status}>
                                  {row.status}
                                </MenuItem>
                                {["Terminated", "Completed", "Deleted"].map(
                                  (category) => (
                                    <MenuItem key={category} value={category}>
                                      {category}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        ) : (
                          <Box>
                            <FormControl
                              size="small"
                              sx={{
                                width: "16ch",
                                backgroundColor: "red",
                                borderRadius: "0px 3px 3px 0px",
                              }}
                            >
                              <Select
                                disabled={!permit.update_row}
                                value={row.status}
                                onChange={(event) =>
                                  handleDelete(
                                    row.id,
                                    row.project_name,
                                    row.project_id,
                                    event.target.value
                                  )
                                }
                                sx={{ color: "white" }}
                              >
                                <MenuItem value={row.status}>
                                  {row.status}
                                </MenuItem>
                                {[
                                  row.status != "Active" && "Active",
                                  row.status != "Terminated" && "Terminated",
                                  row.status != "Completed" && "Completed",
                                  row.status != "Deleted" && "Deleted",
                                ].map((category) => (
                                  <MenuItem key={category} value={category}>
                                    {category}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>
                        )}
                      </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Typography sx={{ m: 3 }}>No project found</Typography>
        )}
      </TableContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 3,
          p: 2,
        }}
      >
        <Button
          disabled={data && data.current_page === 1}
          onClick={(e) => handlePageChange(e, page - 1)}
          sx={{
            textTransform: "none",
            color: data && data.current_page === 1 ? "grey" : "#0030b8",
          }}
        >
          <ArrowBackIosNewIcon /> Back
        </Button>
        {data && data.current_page}
        <Divider orientation="vertical" flexItem />
        {data && data.last_page}
        <Button
          disabled={data && data.last_page === data.current_page}
          onClick={(e) => handlePageChange(e, page + 1)}
          sx={{
            textTransform: "none",
            color:
              data && data.last_page === data.current_page ? "grey" : "#0030b8",
          }}
        >
          Next <ArrowForwardIosIcon />
        </Button>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Updating ...
          </Typography>
        </Box>
      </Backdrop>
    </Paper>
  );
}
