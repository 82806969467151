import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { Button, Stack } from "@mui/material";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LogoutConfirmation from "../logoutConfirmation";
import AllDocuments from "../documentManagement/allDocuments";
import NewDocument from "../documentManagement/newDocument";

const drawerWidth = 270;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(0),
    display: open && "none",
    flexDirection: "column",
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(3),
    display: open && "flex",
    flexDirection: "column",
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function Logout() {
  const [logout, setLogout] = React.useState(false);

  const handleLogout = () => {
    setLogout(!logout);
  };
  return (
    <ListItemButton onClick={handleLogout}>
      {logout && <LogoutConfirmation />}
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText>Logout</ListItemText>
    </ListItemButton>
  );
}

function Alert() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ align: "left" }}>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <Badge badgeContent={3} color="primary">
            <NotificationsNoneIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText>Alerts</ListItemText>
      </ListItemButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
          }}
        >
          Important alerts
        </Box>
        <MenuItem onClick={handleClose}>No alerts yet</MenuItem>
      </Menu>
    </div>
  );
}

function Settings() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <SettingsOutlinedIcon />
        </ListItemIcon>
        <ListItemText>Settings</ListItemText>
      </ListItemButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
          }}
        >
          Settings
        </Box>
        <MenuItem onClick={handleClose}>No settings yet</MenuItem>
      </Menu>
    </div>
  );
}

//Nav bar dropdown view component
function NavbarDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { lg: "none", md: "flex", sm: "flex", xs: "flex" } }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
      >
        <SegmentOutlinedIcon sx={{ fontSize: "2.25em", color: "#0030b8" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem
          component="a"
          href="/document-management/new-document"
          sx={{ width: "100%", textTransform: "none", p: 1 }}
        >
          <ListItemIcon>
            <CreateNewFolderOutlinedIcon />
          </ListItemIcon>
          <ListItemText sx={{ textWrap: "nowrap" }}>New document</ListItemText>
        </MenuItem>
        <MenuItem
          component="a"
          href="/document-management/all-document"
          sx={{ width: "100%", textTransform: "none", p: 1 }}
        >
          <ListItemIcon>
            <FilePresentOutlinedIcon />
          </ListItemIcon>
          <ListItemText sx={{ textWrap: "nowrap" }}>All documents</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Settings />
        </MenuItem>
        <MenuItem>
          <Alert />
        </MenuItem>
        <MenuItem>
          <Logout />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function Archiver() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 1100 ? true : false
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <CssBaseline />
      <Main open={open}>
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 9,
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FBFBFB",
            cursor: "pointer",
          }}
        >
          <Box>
            <Link href="/" underline="none">
              <img
                style={{
                  width: "min(200px,100%)",
                  height: "auto",
                  backgroundColor: "white",
                  border: "3px solid white",
                  borderRadius: 3,
                }}
                src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
              />
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            <ListItemButton href="/document-management/new-document">
              <ListItemIcon>
                <CreateNewFolderOutlinedIcon />
              </ListItemIcon>
              <ListItemText sx={{ textWrap: "nowrap" }}>
                New document
              </ListItemText>
            </ListItemButton>
            <ListItemButton href="/document-management/all-document">
              <ListItemIcon>
                <FilePresentOutlinedIcon />
              </ListItemIcon>
              <ListItemText sx={{ textWrap: "nowrap" }}>
                All documents
              </ListItemText>
            </ListItemButton>
            <Divider orientation="vertical" flexItem />
            <Logout />
            <Divider orientation="vertical" flexItem />
            <Settings />
            <Divider orientation="vertical" flexItem />
            <Alert />
          </Box>
          <NavbarDropdown />
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AllDocuments />} />
              <Route
                path="/document-management/all-document"
                element={<AllDocuments />}
              />
              <Route
                path="/document-management/new-document"
                element={<NewDocument />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </Main>
    </Box>
  );
}
