import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import useFile from "../../hook/useFile";
import Notification from "../notification";

export default function NewDocument() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [document, setDocument] = useState({});

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/documents";

  const { loading, error, confirmation, post } = useFile(url);
  const [isUploading, setIsUploading] = useState(false);
  const [fileError, setFileError] = useState("");

  const handleFileChange = async (e, field) => {
    const file = e.target.files[0];
    const allowedFormats = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
    ];

    if (file && allowedFormats.includes(file.type)) {
      setFileError(""); // Clear any previous errors
      setIsUploading(true); // Show uploading state

      // Simulate file upload delay (or perform real upload here)
      await new Promise((resolve) => setTimeout(resolve, 2000));

      field.onChange(file);
      setIsUploading(false); // Reset uploading state after done
    } else {
      setFileError("Only  .png, .jpg, and .jpeg formats are allowed");
      field.onChange(null); // Clear the field if the file is not valid
    }
  };

  const OnSubmit = (document) => {
    const formData = new FormData();
    Object.keys(document).forEach((key) => {
      if (key === "file") {
        if (document[key]) formData.append("file", document[key]);
      } else {
        formData.append(key, document[key]);
      }
    });

    // Set default value for data_encoder
    const data_encoder = sessionStorage.getItem("user_name") || "gp1"; // default value 'gp1' if session value is missing
    formData.append("data_encoder", data_encoder);

    post(formData, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setDocument({ ...document, [field]: value });
    setValue(field, value);
  };

  return (
    <Box
      sx={{
        width: "min(1200px, 100%)",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New Document
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="document_type"
              control={control}
              defaultValue=""
              rules={{ required: "Document type is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Document type <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("document_type")}
                    error={Boolean(errors.document_type)}
                  />
                  {errors.document_type && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.document_type.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="ref_no"
              control={control}
              defaultValue=""
              rules={{ required: "Ref. no is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Ref. no <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("ref_no")}
                    error={Boolean(errors.ref_no)}
                  />
                  {errors.ref_no && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.ref_no.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="title_subject"
              control={control}
              defaultValue=""
              rules={{ required: "Title/Subject is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Title/Subject <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("title_subject")}
                    error={Boolean(errors.title_subject)}
                  />
                  {errors.title_subject && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.title_subject.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="sender"
              control={control}
              defaultValue=""
              rules={{ required: "Sender is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Sender <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("sender")}
                    error={Boolean(errors.sender)}
                  />
                  {errors.sender && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.sender.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="receiver"
              control={control}
              defaultValue=""
              rules={{ required: "Receiver is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Receiver <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("receiver")}
                    error={Boolean(errors.receiver)}
                  />
                  {errors.receiver && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.receiver.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="file"
              control={control}
              defaultValue=""
              rules={{ required: "File is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    sx={{ mt: 1, color: "#0030b8" }}
                    disabled={isUploading} // Disable button while uploading
                  >
                    {isUploading ? (
                      <>
                        Uploading...
                        <CircularProgress size={20} sx={{ ml: 1 }} />
                      </>
                    ) : (
                      "Upload File"
                    )}
                    <input
                      type="file"
                      accept=".pdf, .png, .jpg, .jpeg" // Restrict to specific file types
                      hidden
                      onChange={(e) => handleFileChange(e, field)}
                    />
                  </Button>
                  {fileError && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {fileError}
                    </p>
                  )}
                  {errors.file && !fileError && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.file.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            disabled={isUploading}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>

          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
