import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Stack,
  Button,
  Input,
  InputLabel,
  FormGroup,
  FormControl,
  Typography,
} from "@mui/material";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { useForm, Controller } from "react-hook-form";
import usePost from "../hook/usePost";
import Admin from "./pages/admin";
import ProjectManager from "./pages/projectManager";
import Store from "./pages/store";
import SalesPerson from "./pages/salesPerson";
import Archiver from "./pages/archiver";

export default function ProtectedRoute() {
  const [token, setToken] = useState("");

  const url = "https://gp.maflink.com/api/api/users/login";
  const { loading, error, confirmation, post } = usePost(url);

  console.log(confirmation);

  const Login = () => {
    useEffect(() => {
      if (confirmation) {
        if (confirmation.token) {
          sessionStorage.setItem("token", confirmation.token);
          sessionStorage.setItem("role", confirmation.role);
          sessionStorage.setItem("user_name", confirmation.user_name);
          sessionStorage.setItem("company_id", confirmation.company_id);
          sessionStorage.setItem(
            "permissions",
            JSON.stringify(confirmation.permissions)
          );
          setToken(confirmation.token);
        }
      }
    }, [confirmation]);
    const {
      handleSubmit,
      control,
      formState: { errors },
      setValue,
    } = useForm();
    const [user, setUser] = useState({});

    const onSubmit = () => {
      post(user, "");
    };

    const handleChange = (field) => (e) => {
      const value = e.target.value;
      setUser({ ...user, [field]: value });
      setValue(field, value);
    };

    return (
      <Box
        sx={{
          height: window.innerHeight,
          backgroundColor: "white",
          display: "flex",
          flexFlow: "row wrap",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            display: { md: "flex", sm: "none", xs: "none" },
            mt: 7,
          }}
        >
          <img
            src="/logo-large.PNG"
            alt="Logo"
            style={{ width: 300, borderRadius: 15 }}
          />
        </Box>
        <Box
          sx={{
            display: { md: "none", sm: "flex", xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src="/logo.png"
            alt="Logo"
            style={{ width: "85%", borderRadius: 5 }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <Stack
            sx={{
              width: "90%",
              borderBottom: "2px solid whitesmoke",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: 3,
              m: 4,
              pb: 3,
            }}
          >
            <PersonAddOutlinedIcon />
            <Typography color="inherit" variant="h6">
              Login Form
            </Typography>
          </Stack>
          {confirmation && (
            <Typography
              sx={{
                pl: 5,
                color:
                  confirmation.message ===
                  "The provided credentials are incorrect."
                    ? "red"
                    : "primary",
              }}
            >
              {confirmation.message}
            </Typography>
          )}
          <FormGroup
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
              padding: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexFlow: "row wrap",
                gap: 3,
                "& .MuiTextField-root": { m: 1, width: "21ch" },
              }}
            >
              {[
                {
                  name: "user_name",
                  label: "User Name",
                  type: "text",
                },
                {
                  name: "password",
                  label: "Password",
                  type: "password",
                },
              ].map(({ name, label, type }) => (
                <Controller
                  key={name}
                  name={name}
                  control={control}
                  defaultValue=""
                  rules={{ required: `${label} is required` }}
                  render={({ field }) => (
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "21ch" }}
                    >
                      <InputLabel>
                        {label} <span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Input
                        {...field}
                        type={type}
                        onChange={handleChange(name)}
                        error={Boolean(errors[name])}
                      />
                      {errors[name] && (
                        <Typography
                          color="error"
                          variant="body2"
                          sx={{ mt: 1 }}
                        >
                          {errors[name].message}
                        </Typography>
                      )}
                    </FormControl>
                  )}
                />
              ))}
            </Box>
            <Box sx={{ width: "100%", borderRadius: 1 }}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit(onSubmit)}
                sx={{
                  backgroundColor: "#0000FE",
                  mt: 2,
                  fontSize: "1.15em",
                }}
              >
                Submit
              </Button>
            </Box>
          </FormGroup>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 3,
            }}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ mt: 2 }}>
              Loading . . .
            </Typography>
          </Box>
        </Backdrop>
      </Box>
    );
  };

  if (sessionStorage.getItem("token")) {
    if (sessionStorage.getItem("role") === "Super admin") {
      return <Admin />;
    } else if (sessionStorage.getItem("role") === "Admin") {
      return <Admin />;
    } else if (sessionStorage.getItem("role") === "Project manager") {
      return <ProjectManager />;
    } else if (sessionStorage.getItem("role") === "Store") {
      return <Store />;
    } else if (sessionStorage.getItem("role") === "Sales person") {
      return <SalesPerson />;
    } else if (sessionStorage.getItem("role") === "Archiver") {
      return <Archiver />;
    } else {
      return <Login />;
    }
  } else {
    return <Login />;
  }
}
