import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import Notification from "../notification";

export default function NewProject() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [project, setProject] = useState({});

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/projects";

  const { loading, error, confirmation, post } = usePost(url);

  const categories = [
    "Mechanical",
    "Electromechanical",
    "Electrical",
    "Others",
  ];

  const OnSubmit = (project) => {
    project.date_of_start = dayjs(project.date_of_start).format("YYYY-MM-DD");
    project.estimated_completion_date = dayjs(
      project.estimated_completion_date
    ).format("YYYY-MM-DD");
    project.date_end = dayjs(project.date_end).format("YYYY-MM-DD");
    project.data_encoder = sessionStorage.getItem("user_name");
    post(project, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setProject({ ...project, [field]: value });
    setValue(field, value);
  };

  const handleDateChange = (field) => (date) => {
    setProject({ ...project, [field]: date });
    setValue(field, date);
  };

  return (
    <Box
      sx={{
        width: "min(1200px, 100%)",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New project
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="project_name"
              control={control}
              defaultValue=""
              rules={{ required: "Project name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("project_name")}
                    error={Boolean(errors.project_name)}
                  />
                  {errors.project_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="project_id"
              control={control}
              defaultValue=""
              rules={{ required: "Project id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project id <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("project_id")}
                    error={Boolean(errors.project_id)}
                  />
                  {errors.project_id && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="project_owner"
              control={control}
              defaultValue=""
              rules={{ required: "project owner is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project owner <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("project_owner")}
                    error={Boolean(errors.project_owner)}
                  />
                  {errors.project_owner && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_owner.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="project_consultant"
              control={control}
              defaultValue=""
              rules={{ required: "project consultant is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project consultant <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("project_consultant")}
                    error={Boolean(errors.project_consultant)}
                  />
                  {errors.project_consultant && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_consultant.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="project_manager"
              control={control}
              defaultValue=""
              rules={{ required: "Project manager is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project manager <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("project_manager")}
                    error={Boolean(errors.project_manager)}
                  />
                  {errors.project_manager && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_manager.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="budget"
              control={control}
              defaultValue=""
              rules={{ required: "Budget is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Budget <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.budget)}
                  />
                  {errors.budget && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.budget.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: "Project location is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project location <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input {...field} error={Boolean(errors.location)} />
                  {errors.location && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.location.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="date_of_start"
                control={control}
                defaultValue={null}
                rules={{ required: "Date start is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Date start <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("date_of_start")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.date_of_start)}
                    />
                    {errors.date_of_start && (
                      <p
                        style={{ color: "red", fontSize: "0.8rem", margin: 0 }}
                      >
                        {errors.date_of_start.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name="estimated_completion_date"
                control={control}
                defaultValue={null}
                rules={{ required: "Expiration date is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Completion date
                          <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("estimated_completion_date")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.estimated_completion_date)}
                    />
                    {errors.estimated_completion_date && (
                      <p
                        style={{ color: "red", fontSize: "0.8rem", margin: 0 }}
                      >
                        {errors.estimated_completion_date.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
            </LocalizationProvider>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
