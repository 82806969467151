import ProtectedRoute from "./component/ProtectedRoute";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ProtectedRoute />
      </header>
    </div>
  );
}

export default App;
