import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Slide from "@mui/material/Slide";
import useFetch from "../../hook/useFetch";
import IssueSolution from "./issuesSolution";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewIssue({ handleIssue, projectName }) {
  const projectsUrl = `https://gp.maflink.com/api/api/project-issues?projectName=${projectName}`;
  const [projectData, fetchProject] = useFetch(projectsUrl);

  console.log(projectData);

  return (
    <React.Fragment>
      <Dialog fullScreen open={true} TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative", backgroundColor: "#0030b8" }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {projectName} Issues
            </Typography>
            <Button
              autoFocus
              color="inherit"
              onClick={() => handleIssue(false)}
            >
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          {projectData &&
            projectData.map((data) => (
              <Box sx={{ width: "85%", ml: 5, pb: 7 }}>
                <Box sx={{ mb: 3 }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexFlow: "row wrap",
                      alignItems: "flex-end",
                      gap: 7,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {data.title}
                    </Typography>
                    <Box>
                      {data.solution && (
                        <Button
                          sx={{
                            fontWeight: "bold",
                            fontSize: "0.7em",
                            fontStyle: "italic",
                          }}
                        >
                          Recommended
                        </Button>
                      )}
                    </Box>
                  </Box>
                  <Typography sx={{ fontSize: "0.75em" }}>
                    (Issued by: {data.data_encoder})
                  </Typography>
                  <Typography>{data.detail}</Typography>
                </Box>
                {data.solution ? (
                  <Box sx={{ mb: 3 }}>
                    <Typography sx={{ fontWeight: "bold" }}>
                      Solution
                    </Typography>
                    <Typography sx={{ fontSize: "0.75em" }}>
                      (By: {data.solved_by})
                    </Typography>
                    <Typography>{data.solution}</Typography>
                  </Box>
                ) : (
                  <IssueSolution id={data.id} />
                )}
                <Divider />
              </Box>
            ))}
        </List>
      </Dialog>
    </React.Fragment>
  );
}
