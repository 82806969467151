import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import useFetch from "../../hook/useFetch";
import Notification from "../notification";
import Receipt from "./receipt";
import { Divider } from "@mui/material";

export default function NewSales() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [product, setProduct] = useState({});
  const [rows, setRows] = useState([]);
  const [receipt, setReceipt] = useState(false);
  const [generateReceipt, setGenerateReceipt] = useState(true);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/sales";
  const fetchUrl = `https://gp.maflink.com/api/api/products?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const { loading, error, confirmation, post } = usePost(url);
  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    data && setRows(data);
  }, [data]);

  const productId = rows.filter(
    (row) => row.product_name === product.product_name
  );

  console.log(confirmation);

  // Update product_id, unit_price, total_price and product_category when product_name changes
  useEffect(() => {
    if (product) {
      setProduct({
        ...product,
        product_id: productId[0] ? productId[0].product_id : "",
        product_category: productId[0] ? productId[0].product_category : "",
        unit_price: productId[0] ? productId[0].selling_price : "",
        total_price: product.quantity
          ? product.quantity * productId[0].selling_price
          : "",
      });

      setValue("product_id", productId[0] ? productId[0].product_id : "");
      setValue(
        "product_category",
        productId[0] ? productId[0].product_category : ""
      );
      setValue("unit_price", productId[0] ? productId[0].selling_price : "");

      productId[0] &&
        product.quantity &&
        setValue(
          "total_price",
          product.quantity ? product.quantity * productId[0].selling_price : ""
        );
    }
  }, [
    product.product_name,
    product.quantity,
    product.unit_price,
    product.total_price,
    rows,
    setValue,
  ]);

  const OnSubmit = (product) => {
    product.sales_date = dayjs(product.sales_date).format("YYYY-MM-DD");
    product.due_date = dayjs(product.due_date).format("YYYY-MM-DD");
    product.data_encoder = sessionStorage.getItem("user_name");
    product.tax = product.taxChecked ? 0.15 * product.total_price : 0;
    product.withholding_tax = product.withChecked
      ? 0.02 * product.total_price
      : 0;
    post(product, token);
    setReceipt(true);
    setGenerateReceipt(false);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setProduct({ ...product, [field]: value });
    setValue(field, value);
  };

  const handleDateChange = (field) => (date) => {
    setProduct({ ...product, [field]: date });
    setValue(field, date);
  };

  function handleReceipt() {
    setReceipt(!receipt);
  }
  return (
    <Box sx={{ maxWidth: 1200 }}>
      {receipt && <Receipt data={product} />}
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Box
            sx={{
              width: "100%",
              backgroundColor: "whitesmoke",
              p: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <AddIcon />
            <Typography color="inherit" variant="h6">
              New sales
            </Typography>
          </Box>
          <Button
            disabled={generateReceipt}
            sx={{ whiteSpace: "nowrap", textDecoration: "underline" }}
            onClick={handleReceipt}
          >
            Generate receipt
          </Button>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="product_name"
              control={control}
              defaultValue=""
              rules={{ required: "Product name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("product_name")}
                    error={Boolean(errors.product_name)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {rows.map((row) => (
                      <MenuItem key={row.product_name} value={row.product_name}>
                        {row.quantity > 0 && row.product_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.product_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_id"
              control={control}
              defaultValue=""
              rules={{ required: "Product id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product id <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    value={productId[0] ? productId[0].product_id : ""}
                    onChange={handleChange("product_id")}
                    error={Boolean(errors.product_id)}
                  />
                  {errors.product_id && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_category"
              control={control}
              defaultValue=""
              rules={{ required: "Product category is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    value={productId[0] ? productId[0].product_category : ""}
                    onChange={handleChange("product_category")}
                    error={Boolean(errors.product_category)}
                  />
                  {errors.product_category && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_category.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{
                required: "Quantity is required",
                min: {
                  value: 1,
                  message: `Minimum quantity to sell is 1`,
                },

                max: {
                  value: productId[0] ? productId[0].quantity : Infinity,
                  message:
                    productId[0] && productId[0].quantity > 0
                      ? `Maximum available quantity is ${productId[0].quantity}`
                      : `No  enough product`,
                },
              }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="unit_price"
              control={control}
              defaultValue=""
              rules={{ required: "Unit price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Unit price <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    value={productId[0] ? productId[0].selling_price : ""}
                    onChange={handleChange("unit_price")}
                    error={Boolean(errors.unit_price)}
                  />
                  {errors.unit_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.unit_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="total_price"
              control={control}
              defaultValue=""
              rules={{ required: "Total price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Total price <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("total_price")}
                    error={Boolean(errors.total_price)}
                  />
                  {errors.total_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.total_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="customer_name"
              control={control}
              defaultValue=""
              // rules={{ required: "Customer name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Customer name</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("customer_name")}
                    error={Boolean(errors.supplier)}
                  />
                  {errors.customer_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.customer_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="customer_contact"
              control={control}
              defaultValue=""
              // rules={{ required: "Customer contact is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Customer contact</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("customer_contact")}
                    error={Boolean(errors.customer_contact)}
                  />
                  {errors.customer_contact && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.customer_contact.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="payment_method"
              control={control}
              defaultValue=""
              rules={{ required: "Payment method is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Payment method <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("payment_method")}
                    error={Boolean(errors.payment_method)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {["Cash", "Transfer", "Check"].map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.payment_method && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.payment_method.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            {product.payment_method === "Transfer" && (
              <>
                <Controller
                  name="txn"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Txn is required" }}
                  render={({ field }) => (
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "21ch" }}
                    >
                      <InputLabel>
                        Txn:<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Input
                        {...field}
                        onChange={handleChange("txn")}
                        error={Boolean(errors.txn)}
                      />
                      {errors.txn && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: 0,
                          }}
                        >
                          {errors.txn.message}
                        </p>
                      )}
                    </FormControl>
                  )}
                />
                <Controller
                  name="bank_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Bank name is required" }}
                  render={({ field }) => (
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "21ch" }}
                    >
                      <InputLabel>
                        Bank name<span style={{ color: "red" }}>*</span>
                      </InputLabel>
                      <Input
                        {...field}
                        onChange={handleChange("bank_name")}
                        error={Boolean(errors.bank_name)}
                      />
                      {errors.bank_name && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: 0,
                          }}
                        >
                          {errors.bank_name.message}
                        </p>
                      )}
                    </FormControl>
                  )}
                />
              </>
            )}
            {product.payment_method === "Check" && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="due_date"
                  control={control}
                  defaultValue={null}
                  rules={{ required: "Due date is required" }}
                  render={({ field }) => (
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, width: "21ch" }}
                    >
                      <DatePicker
                        label={
                          <Typography>
                            Due date <span style={{ color: "red" }}>*</span>
                          </Typography>
                        }
                        {...field}
                        onChange={handleDateChange("due_date")}
                        renderInput={(params) => (
                          <Input
                            type="date"
                            {...params.inputProps}
                            {...params.InputProps}
                          />
                        )}
                        error={Boolean(errors.due_date)}
                      />
                      {errors.due_date && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "0.8rem",
                            margin: 0,
                          }}
                        >
                          {errors.due_date.message}
                        </p>
                      )}
                    </FormControl>
                  )}
                />
              </LocalizationProvider>
            )}
            <Controller
              name="invoice_number"
              control={control}
              defaultValue=""
              rules={{ required: "Invoice number is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Invoice number <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("invoice_number")}
                    error={Boolean(errors.invoice_number)}
                  />
                  {errors.invoice_number && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.invoice_number.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="tin"
              control={control}
              defaultValue=""
              rules={{ required: "TIN is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    TIN <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("tin")}
                    error={Boolean(errors.tin)}
                  />
                  {errors.tin && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.tin.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="batch_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Batch number</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("batch_number")}
                    error={Boolean(errors.batch_number)}
                  />
                  {errors.batch_number && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.batch_number.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name="sales_date"
                control={control}
                defaultValue={null}
                rules={{ required: "Date of sales is required" }}
                render={({ field }) => (
                  <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                    <DatePicker
                      label={
                        <Typography>
                          Date of sales <span style={{ color: "red" }}>*</span>
                        </Typography>
                      }
                      {...field}
                      onChange={handleDateChange("sales_date")}
                      renderInput={(params) => (
                        <Input
                          type="date"
                          {...params.inputProps}
                          {...params.InputProps}
                        />
                      )}
                      error={Boolean(errors.sales_date)}
                    />
                    {errors.sales_date && (
                      <p
                        style={{ color: "red", fontSize: "0.8rem", margin: 0 }}
                      >
                        {errors.sales_date.message}
                      </p>
                    )}
                  </FormControl>
                )}
              />
            </LocalizationProvider> */}
          </Box>
          <Divider sx={{ width: "100%" }} />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="taxChecked"
              control={control}
              defaultValue={false} // Default value should be a boolean for checkbox
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        {...field}
                        checked={field.value} // Use checked instead of defaultChecked
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          field.onChange(isChecked); // Update React Hook Form state
                          setProduct({
                            ...product,
                            taxChecked: isChecked
                              ? 0.15 * product.total_price
                              : 0, // Set value based on checkbox state
                          });
                        }}
                        error={Boolean(errors.taxChecked)}
                      />
                    }
                    label="Tax"
                  />
                  {errors.tax && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.taxChecked.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="withChecked"
              control={control}
              defaultValue={false} // Default value should be a boolean for checkbox
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        {...field}
                        checked={field.value} // Use checked instead of defaultChecked
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          field.onChange(isChecked); // Update React Hook Form state
                          setProduct({
                            ...product,
                            withChecked: isChecked
                              ? 0.02 * product.total_price
                              : 0, // Set value based on checkbox state
                          });
                        }}
                        error={Boolean(errors.withChecked)}
                      />
                    }
                    label="Withholding"
                  />
                  {errors.withholding_tax && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.withChecked.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: " #0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
