import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import Notification from "../notification";

export default function NewOrder() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [product, setProduct] = useState({});

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/orders";

  const { loading, error, confirmation, post } = usePost(url);

  const categories = [
    "Mechanical",
    "Electromechanical",
    "Electrical",
    "Others",
  ];

  // Effect to calculate total_price whenever quantity or unit_price changes
  useEffect(() => {
    const total_price = product.quantity * product.unit_price;
    product.quantity &&
      product.unit_price &&
      setValue("total_price", total_price);
  }, [product.quantity, product.unit_price, setValue]);

  const OnSubmit = (product) => {
    product.date_received = dayjs(product.date_received).format("YYYY-MM-DD");
    product.date_expired = dayjs(product.date_expired).format("YYYY-MM-DD");
    product.data_encoder = sessionStorage.getItem("user_name");
    post(product, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setProduct({ ...product, [field]: value });
    setValue(field, value);
  };

  const handleDateChange = (field) => (date) => {
    setProduct({ ...product, [field]: date });
    setValue(field, date);
  };

  return (
    <Box sx={{ maxWidth: 1200 }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New order
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="product_name"
              control={control}
              defaultValue=""
              rules={{ required: "Product name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("product_name")}
                    error={Boolean(errors.product_name)}
                  />
                  {errors.product_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_id"
              control={control}
              defaultValue=""
              rules={{ required: "Product id is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product id <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("product_id")}
                    error={Boolean(errors.product_id)}
                  />
                  {errors.product_id && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_id.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_category"
              control={control}
              defaultValue=""
              rules={{ required: "Product category is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product category <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("product_category")}
                    error={Boolean(errors.product_category)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.product_category && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_category.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="product_description"
              control={control}
              defaultValue=""
              rules={{ required: "Product description is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Product description <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("product_description")}
                    error={Boolean(errors.product_description)}
                  />
                  {errors.product_description && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.product_description.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{ required: "Quantity is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="unit_price"
              control={control}
              defaultValue=""
              // rules={{ required: "Unit price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Unit price</InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("unit_price")}
                    error={Boolean(errors.unit_price)}
                  />
                  {errors.unit_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.unit_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="total_price"
              control={control}
              defaultValue=""
              // rules={{ required: "Total price is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Total price</InputLabel>
                  <Input
                    {...field}
                    type="number"
                    error={Boolean(errors.total_price)}
                  />
                  {errors.total_price && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.total_price.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="supplier"
              control={control}
              defaultValue=""
              // rules={{ required: "Supplier is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Supplier</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier")}
                    error={Boolean(errors.supplier)}
                  />
                  {errors.supplier && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.supplier.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="supplier_contact"
              control={control}
              defaultValue=""
              // rules={{ required: "Supplier contact is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Supplier contact</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("supplier_contact")}
                    error={Boolean(errors.supplier_contact)}
                  />
                  {errors.supplier_contact && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.supplier_contact.message}
                    </p>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: " #0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
