import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PinDropIcon from "@mui/icons-material/PinDrop";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../../hook/usePost";
import useFetch from "../../../hook/useFetch";
import Notification from "../../notification";

export default function DeployEquipment() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();

  const [rows, setRows] = useState([]);
  const [projects, setProjects] = useState([]);
  const [equipment, setEquipment] = useState({ reason: "Deploy" });

  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/equipment-transactions";

  const projectsUrl = `https://gp.maflink.com/api/api/projects?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}`;

  const equipmentUrl = `https://gp.maflink.com/api/api/equipments?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}&location=${encodeURIComponent(null)}`;

  const { loading, error, confirmation, post } = usePost(url);

  const [projectData, fetchProject] = useFetch(projectsUrl);
  const [equipmentData, fetchequipment] = useFetch(equipmentUrl);

  useEffect(() => {
    equipmentData && setRows(equipmentData);
    projectData && setProjects(projectData.data);
  }, [equipmentData, projectData]);
  console.log(equipment);

  const equipmentName = rows.filter(
    (row) => row.equipment_name === equipment.equipment_name
  );

  useEffect(() => {
    if (equipment) {
      setEquipment({
        ...equipment,
        equipment_category: equipmentName[0]
          ? equipmentName[0].equipment_category
          : "",
        brand: equipmentName[0] ? equipmentName[0].brand : "",
        equipment_description: equipmentName[0]
          ? equipmentName[0].equipment_description
          : "",
        unit_price: equipmentName[0] ? equipmentName[0].unit_price : "",
        total_price: equipmentName[0] ? equipmentName[0].total_price : "",
      });

      setValue(
        "equipment_category",
        equipmentName[0] ? equipmentName[0].equipment_category : ""
      );
      setValue("brand", equipmentName[0] ? equipmentName[0].brand : "");
      setValue(
        "equipment_description",
        equipmentName[0] ? equipmentName[0].equipment_description : ""
      );
      setValue(
        "unit_price",
        equipmentName[0] ? equipmentName[0].unit_price : ""
      );
      setValue(
        "total_price",
        equipmentName[0] ? equipmentName[0].total_price : ""
      );
    }
  }, [
    equipment.equipment_category,
    equipment.equipment_description,
    equipment.unit_price,
    equipment.total_price,
    rows,
    setValue,
  ]);

  const OnSubmit = (equipment) => {
    equipment.date_expired = dayjs(equipment.date_expired).format("YYYY-MM-DD");
    equipment.data_encoder = sessionStorage.getItem("user_name");
    post(equipment, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setEquipment({ ...equipment, [field]: value });
    setValue(field, value);
  };

  return (
    <Box sx={{ width: "min(1000px, 100%)" }}>
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        {(sessionStorage.getItem("role") === "Admin" ||
          sessionStorage.getItem("role") === "Store") && (
          <Stack
            sx={{
              width: "100%",
              backgroundColor: "whitesmoke",
              borderBottom: "2px solid #0030b8",
              p: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <PinDropIcon />
            <Typography color="inherit" variant="h6">
              Deploy equipment
            </Typography>
          </Stack>
        )}
        <FormGroup
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1, width: "21ch" },
            }}
          >
            <Controller
              name="equipment_name"
              control={control}
              defaultValue=""
              rules={{ required: "Equipment name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Equipment name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("equipment_name")}
                    error={Boolean(errors.equipment_name)}
                  >
                    {rows.map((row) => (
                      <MenuItem
                        key={row.equipment_name}
                        value={row.equipment_name}
                      >
                        {row.equipment_name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.equipment_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.equipment_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="quantity"
              control={control}
              defaultValue=""
              rules={{
                required: "Quantity is required",
                min: {
                  value: 1,
                  message: `Minimum quantity to deploy is 1`,
                },

                max: {
                  value: equipmentName[0]
                    ? equipmentName[0].quantity
                    : Infinity,
                  message:
                    equipmentName[0] && equipmentName[0].quantity > 0
                      ? `Maximum available quantity is ${equipmentName[0].quantity}`
                      : `No  enough equipment`,
                },
              }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    type="number"
                    onChange={handleChange("quantity")}
                    error={Boolean(errors.quantity)}
                  />
                  {errors.quantity && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.quantity.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="serial_no"
              control={control}
              defaultValue=""
              rules={{ required: "Serial no is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Serial no <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("serial_no")}
                    error={Boolean(errors.serial_no)}
                  />
                  {errors.serial_no && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.serial_no.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="location"
              control={control}
              defaultValue=""
              rules={{ required: "Location is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Location <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("location")}
                    error={Boolean(errors.location)}
                  >
                    {projects.map((project) => (
                      <MenuItem
                        key={project.project_name}
                        value={project.project_name}
                      >
                        {project.project_name}
                      </MenuItem>
                    ))}
                    <MenuItem value="Other">Other service</MenuItem>
                  </Select>
                  {errors.location && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.location.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="assigned_to"
              control={control}
              defaultValue=""
              rules={{ required: "Assigned person is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Assigned to <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("assigned_to")}
                    error={Boolean(errors.asigned_to)}
                  />
                  {errors.assigned_to && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.assigned_to.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="contact"
              control={control}
              defaultValue=""
              rules={{ required: "Contact is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Contact<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("contact")}
                    error={Boolean(errors.contact)}
                  />
                  {errors.contact && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.contact.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="plate_no"
              control={control}
              defaultValue=""
              rules={{ required: "Plate no is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Plate no<span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("plate_no")}
                    error={Boolean(errors.plate_no)}
                  />
                  {errors.plate_no && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.plate_no.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="notes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>Notes</InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("notes")}
                    error={Boolean(errors.notes)}
                  />
                  {errors.notes && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.notes.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
