import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import usePost from "../../hook/usePost";
import useFetch from "../../hook/useFetch";
import { TextField } from "@mui/material";
import Notification from "../notification";

export default function ProjectIssue() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [projectIssue, setProjectIssue] = useState({});

  const token = sessionStorage.getItem("token");

  const projectsUrl = `https://gp.maflink.com/api/api/projects?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}`;

  const [projectData, fetchProject] = useFetch(projectsUrl);

  const url = "https://gp.maflink.com/api/api/project-issues";

  const { loading, error, confirmation, post } = usePost(url);

  const OnSubmit = (projectIssue) => {
    projectIssue.data_encoder = sessionStorage.getItem("user_name");
    post(projectIssue, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setProjectIssue({ ...projectIssue, [field]: value });
    setValue(field, value);
  };

  return (
    <Box
      sx={{
        width: "min(1200px, 100%)",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            New project issue
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              width: "min(1000px, 100%)",
              display: "flex",
              flexWrap: "wrap",
              gap: 3,
              "& .MuiTextField-root": { m: 1 },
            }}
          >
            <Controller
              name="project_name"
              control={control}
              defaultValue=""
              rules={{ required: "Project name is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Project name <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Select
                    {...field}
                    onChange={handleChange("project_name")}
                    error={Boolean(errors.project_name)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {projectData &&
                      projectData.data.map((project) => (
                        <MenuItem
                          key={project.project_name}
                          value={project.project_name}
                        >
                          {project.project_name}
                        </MenuItem>
                      ))}
                  </Select>
                  {errors.project_name && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.project_name.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="title"
              control={control}
              defaultValue=""
              rules={{ required: "Project issue title is required" }}
              render={({ field }) => (
                <FormControl variant="standard" sx={{ m: 1, width: "21ch" }}>
                  <InputLabel>
                    Title <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Input
                    {...field}
                    onChange={handleChange("title")}
                    error={Boolean(errors.title)}
                  />
                  {errors.title && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.title.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
            <Controller
              name="detail"
              control={control}
              defaultValue=""
              rules={{ required: "Deatails of issue is required" }}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  sx={{ m: 1, width: "min(800px, 100%)" }}
                >
                  <TextField
                    {...field}
                    variant="standard"
                    label={
                      <span>
                        Deatails of issue
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    multiline
                    onChange={handleChange("detail")}
                  />

                  {errors.detail && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.detail.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
