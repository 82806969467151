import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import useUpdate from "../../hook/useUpdate";
import Notification from "../notification";

export default function IssueSolution({ id }) {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm();
  const [solution, setSolution] = useState({});

  const token = sessionStorage.getItem("token");

  const url = "https://gp.maflink.com/api/api/project-issues/" + id;

  const { loading, error, confirmation, update } = useUpdate(url);

  const OnSubmit = (solution) => {
    solution.solved_by = sessionStorage.getItem("user_name");
    update(solution, token);
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setSolution({ ...solution, [field]: value });
    setValue(field, value);
  };

  return (
    <Box
      sx={{
        width: "min(1200px, 90%)",
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Stack
          sx={{
            width: "100%",
            backgroundColor: "whitesmoke",
            borderBottom: "2px solid #0030b8",
            p: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AddIcon />
          <Typography color="inherit" variant="h6">
            Recommend solution
          </Typography>
        </Stack>
        <FormGroup
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            padding: 3,
          }}
        >
          <Box
            sx={{
              width: "min(1000px, 100%)",
            }}
          >
            <Controller
              name="solution"
              control={control}
              defaultValue=""
              rules={{ required: "Recommendation is required" }}
              render={({ field }) => (
                <FormControl
                  variant="standard"
                  sx={{ m: 1, width: "min(1200px, 100%)" }}
                >
                  <TextField
                    {...field}
                    variant="standard"
                    label={
                      <span>
                        Recommendation
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    multiline
                    onChange={handleChange("solution")}
                  />

                  {errors.solution && (
                    <p style={{ color: "red", fontSize: "0.8rem", margin: 0 }}>
                      {errors.solution.message}
                    </p>
                  )}
                </FormControl>
              )}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit(OnSubmit)}
            sx={{ mt: 2, backgroundColor: "#0030b8" }}
          >
            Submit
          </Button>
          {confirmation && <Notification message={confirmation.message} />}
          {error && <Notification message={error} color="red" />}
        </FormGroup>
      </Paper>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Submitting ...
          </Typography>
        </Box>
      </Backdrop>
    </Box>
  );
}
