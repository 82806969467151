import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

export default function DownloadFile({ ref_no, file_path }) {
  const handleDownload = () => {
    window.open(
      "https://gp.maflink.com/api/storage/app/public/" + file_path,
      "_blank"
    );
  };

  return (
    <Button onClick={handleDownload}>
      <Download sx={{ ml: -2 }} /> {ref_no}
    </Button>
  );
}
