import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Divider } from "@mui/material";
import useUpdate from "../../hook/useUpdate";

export default function MaterialChange({ name, quantity, condition, url }) {
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const token = sessionStorage.getItem("token");

  const [material, setMaterial] = useState({
    material_name: name,
    quantity: quantity,
    condition: condition,
  });

  const { loading, error, confirmation, update } = useUpdate(url);

  const handleClose = () => {
    setOpen(false);
  };

  async function handleDelete() {
    update(material, token);
    setOpen(false);
  }

  console.log(confirmation);
  return (
    <React.Fragment>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Notice"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to change <b>{name} </b>?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button sx={{ color: "#383315" }} autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ color: "#383315" }} onClick={handleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
