import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import AppsIcon from "@mui/icons-material/Apps";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import { Button, Stack } from "@mui/material";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import useUpdate from "../../../hook/useUpdate";
import useFetch from "../../../hook/useFetch";
import ExportToExcel from "../../exportToExcel";
import MaterialChange from "../materialChange";

const EditableCell = ({ row, edit, field, align, onBlur }) => (
  <TableCell
    onClick={(e) =>
      row.id === edit
        ? (e.target.contentEditable = true)
        : (e.target.contentEditable = false)
    }
    onBlur={(e) => onBlur(e.target.textContent)}
    align={align}
    sx={{ whiteSpace: "nowrap" }}
  >
    {row[field]}
  </TableCell>
);

//Custom styling
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.45),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),

    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "16ch",
      "&:focus": {
        width: "31ch",
      },
    },
  },
}));

//Component for table head
function EnhancedTableHead({
  onSelectAllClick,
  numSelected,
  rowCount,
  date_start,
  date_end,
  location,
}) {
  return (
    <TableHead
      sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {[
          "No",
          "Material name",
          "Category",
          !date_start && !date_end && !location && "Brand",
          "Description",
          !date_start && !date_end && !location && "Supplier",
          !date_start && !date_end && !location && "Supplier contact",
          !date_start && !date_end && !location && "Measuring unit",
          "Quantity",
          "Unit price",
          "Total price",
          "Selling price",
          !date_start && !date_end && !location && "Expiration date",
          !date_start && !date_end && !location && "Last Updated",
          !date_start && !date_end && !location && "Notes",
          !date_start && !date_end && !location && "Actions",
        ]
          .filter(Boolean) // Filter out falsy values, like false or null
          .map((headCell) => (
            <TableCell
              key={headCell}
              sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
            >
              {headCell}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

//Dropdown view component
function Dropdown({ handleSearch }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
          ml: -1,
        }}
      >
        <AppsIcon sx={{ cursor: "pointer" }} />
        <Typography sx={{ color: "black" }}>Materials</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(e) => {
            handleSearch("Good");
            handleClose();
          }}
        >
          <ListItemIcon>
            <CheckCircleOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Good material</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Deleted");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted material</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("expired");
            handleClose();
          }}
        >
          <ListItemIcon>
            <LockClockOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Expired material</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}

function MaterialDeploymentLocation({ handleLocation }) {
  const [projects, setProjects] = useState([]);
  const projectsUrl = `https://gp.maflink.com/api/api/projects?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(null)}&date_end=${encodeURIComponent(
    null
  )}`;

  const [projectData, fetchProject] = useFetch(projectsUrl);

  useEffect(() => {
    projectData && setProjects(projectData.data);
  }, [projectData]);

  return (
    <>
      <FormControl variant="standard" sx={{ width: "21ch" }}>
        <InputLabel>Dispatch Location</InputLabel>
        <Select onChange={(e) => handleLocation(e.target.value)}>
          <MenuItem value=" ">
            <em>All Dispatches</em>
          </MenuItem>
          {projects.map((project) => (
            <MenuItem key={project.project_name} value={project.project_name}>
              {project.project_name}
            </MenuItem>
          ))}
          <MenuItem value="Other">
            <em>Other service</em>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

//Mobile dropdown view component
function MobileDropdown({ handleSearch, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { lg: "none", md: "none", sm: "flex", xs: "flex" } }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "#3f3f3fd6",
        }}
      >
        <DragIndicatorIcon sx={{ cursor: "pointer" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem sx={{ ml: -3, p: 3 }}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search material"
              onChange={(e) => handleSearch(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            />
          </Search>
        </MenuItem>
        <MenuItem sx={{ ml: -0.5 }}>
          <ExportToExcel data={data} filename="Equipments.xlsx" />
        </MenuItem>
        <MenuItem
          component="a"
          href="/equipments-material/add-material"
          sx={{
            "&:hover": {
              backgroundColor: "blue",
              cursor: "default",
            },
            width: "100%",
            backgroundColor: "#0030b8",
            textTransform: "none",
            color: "white",
            p: 1,
          }}
          onClick={handleClose}
        >
          <ListItemIcon>
            <AddIcon fontSize="medium" sx={{ color: "white" }} />
          </ListItemIcon>
          <ListItemText sx={{ ml: 1, whiteSpace: "nowrap" }}>
            Add material
          </ListItemText>
        </MenuItem>
        <Divider />

        <MenuItem
          onClick={(e) => {
            handleSearch("Good");
            handleClose();
          }}
        >
          <ListItemIcon>
            <CheckCircleOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Good material</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("Deleted");
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Deleted material</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleSearch("expired");
            handleClose();
          }}
        >
          <ListItemIcon>
            <LockClockOutlinedIcon fontSize="medium" />
          </ListItemIcon>
          <ListItemText>Expired material</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography>Filter</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

//Component for table toolbar
function EnhancedTableToolbar({
  handleSearch,
  rows,
  handleDateFilter,
  handleLocation,
}) {
  // Common styles
  const toolbarStyles = {
    width: "100%",
    display: "flex",
    alignContent: "center",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    alignItems: "flex-end",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #235af3",
    position: "sticky",
    left: 0,
    p: 2,
  };

  const stackStyles = {
    display: "flex",
    alignContent: "center",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 3,
  };

  const buttonStyles = {
    width: 160,
    backgroundColor: "#0030b8",
    textTransform: "none",
    color: "white",
    p: 1,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Stack sx={stackStyles}>
        <Dropdown handleSearch={handleSearch} />
      </Stack>
      <Stack
        sx={{
          display: { md: "flex", sm: "none", xs: "none" },
          flexFlow: "row wrap",
          alignItems: "flex-end",
          gap: 2,
        }}
      >
        <MaterialDeploymentLocation handleLocation={handleLocation} />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Material name, brand, supplier  "
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Search>
        <DateFilter handleDateFilter={handleDateFilter} />
        <ExportToExcel data={rows} filename="Materials.xlsx" />
        <Box sx={{ backgroundColor: "#235af3", borderRadius: 1 }}>
          <Button href="/equipments-material/add-material" sx={buttonStyles}>
            <AddIcon />
            <Typography sx={{ ml: 1, whiteSpace: "nowrap" }}>
              Add material
            </Typography>
          </Button>
        </Box>
      </Stack>
      <MobileDropdown handleSearch={handleSearch} data={rows} />
    </Stack>
  );
}

//Collective component to be exported
export default function Materials() {
  const tableContainerHeight = window.innerHeight;
  const [selected, setSelected] = useState([]);
  const [edit, setEdit] = useState(0);
  const [id, setId] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [condition, setCondition] = useState("");
  const [delete_confirmation, setDeleteConfirmation] = useState(false);
  const [rows, setRows] = useState([]);
  const [material, setMaterial] = useState({});
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState(null);
  const [search, setSearch] = useState("");
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);
  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/materials/" + edit;

  const fetchUrl = `https://gp.maflink.com/api/api/materials?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}&location=${encodeURIComponent(location)}`;

  const { loading, error, confirmation, update } = useUpdate(url);

  const [data, fetchData] = useFetch(fetchUrl);

  const permit = JSON.parse(sessionStorage.getItem("permissions"));

  useEffect(() => {
    data && setRows(data);
  }, [data]);
  const handleDateFilter = (date_start, date_end) => {
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : null;

    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  const handleDelete = (id, material_name, condition) => {
    setDeleteConfirmation(!delete_confirmation);
    setId(id);
    setEdit(id);
    setSelectItem(material_name);
    setCondition(condition);
  };

  function handleLocation(loc) {
    setLocation(loc);
  }

  function handleSearch(s) {
    setSearch(s);
  }

  function handleEdit(v) {
    setEdit(v);
  }

  async function handleUpdate() {
    await update(material, token);

    setEdit(0);
    fetchData();
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (no) => selected.indexOf(no) !== -1;

  console.log(confirmation);

  return (
    <Paper sx={{ width: "100%" }}>
      {delete_confirmation && (
        <MaterialChange
          name={selectItem}
          quantity={material.quantity}
          condition={condition}
          url={url}
        />
      )}
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        rows={rows}
        handleDateFilter={handleDateFilter}
        handleLocation={handleLocation}
      />
      <TableContainer
        sx={{
          height: `calc(${tableContainerHeight}px - 200px)`,
          overflowY: "scroll",
        }}
      >
        <Table
          sx={{ minWidth: 750, overflowY: "scroll" }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            date_start={date_start}
            date_end={date_end}
            location={location}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover={row.condition === "Good"}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor: row.condition === "Good" && edit && "pointer",
                    backgroundColor:
                      (row.condition === "Deleted" ||
                        row.condition === "Expired") &&
                      "#ffebc1",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <EditableCell
                    row={row}
                    field="material_name"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, material_name: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="material_category"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, material_category: content })
                    }
                  />
                  {!date_start && !date_end && !location && (
                    <EditableCell
                      row={row}
                      edit={edit}
                      field="brand"
                      align="left"
                      onBlur={(content) =>
                        setMaterial({
                          ...material,
                          brand: content,
                        })
                      }
                    />
                  )}
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="material_description"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({
                        ...material,
                        material_description: content,
                      })
                    }
                  />
                  {!date_start && !date_end && !location && (
                    <>
                      <EditableCell
                        row={row}
                        edit={edit}
                        field="supplier"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({
                            ...material,
                            supplier: content,
                          })
                        }
                      />
                      <EditableCell
                        row={row}
                        edit={edit}
                        field="supplier_contact"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({
                            ...material,
                            supplier_contact: content,
                          })
                        }
                      />
                      <EditableCell
                        row={row}
                        edit={edit}
                        field="measuring_unit"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({
                            ...material,
                            measuring_unit: content,
                          })
                        }
                      />
                    </>
                  )}
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="quantity"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, quantity: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="unit_price"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, unit_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="total_price"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, total_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="selling_price"
                    align="left"
                    onBlur={(content) =>
                      setMaterial({ ...material, selling_price: content })
                    }
                  />

                  {!date_start && !date_end && !location && (
                    <>
                      <EditableCell
                        row={row}
                        edit={edit}
                        field="expiration_date"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({ ...material, expiration_date: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        field="updated_at"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({ ...material, updated_at: content })
                        }
                      />
                      <EditableCell
                        row={row}
                        edit={edit}
                        field="notes"
                        align="left"
                        onBlur={(content) =>
                          setMaterial({ ...material, notes: content })
                        }
                      />
                      <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                        {!date_start && !date_end && (
                          <ButtonGroup sx={{ width: 210, mr: 2 }}>
                            {row.condition === "Good" && (
                              <Box
                                sx={{
                                  width: 100,
                                  backgroundColor:
                                    row.id !== edit ? "#0436c3" : "#235af3",
                                  borderRadius: "3px 0px 0px 3px",
                                }}
                              >
                                {row.id !== edit ? (
                                  <Button
                                    disabled={
                                      !permit.update_row ||
                                      row.condition !== "Good"
                                    }
                                    size="small"
                                    variant="text"
                                    sx={{
                                      width: 100,
                                      color: "white",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      handleEdit(row.id);
                                      setMaterial({
                                        ...material,
                                        material_name: row.material_name,
                                      });
                                    }}
                                  >
                                    <Tooltip title="Click to edit">
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          gap: 1,
                                          mb: -2,
                                        }}
                                      >
                                        <EditNoteIcon />
                                        <Typography> Edit</Typography>
                                      </Box>
                                    </Tooltip>
                                  </Button>
                                ) : (
                                  <Button
                                    size="small"
                                    variant="text"
                                    sx={{
                                      width: 100,
                                      color: "white",
                                      textTransform: "none",
                                    }}
                                    onClick={() => {
                                      handleUpdate();
                                    }}
                                  >
                                    <Tooltip title="Click to save">
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          gap: 1,
                                          mb: -2,
                                        }}
                                      >
                                        <SaveOutlinedIcon />
                                        <Typography> Save</Typography>
                                      </Box>
                                    </Tooltip>
                                  </Button>
                                )}
                              </Box>
                            )}
                            {row.condition === "Good" ? (
                              <Box
                                sx={{
                                  width: 100,
                                  backgroundColor: "#cf4b4b",
                                  borderRadius: "0px 3px 3px 0px",
                                }}
                              >
                                <Button
                                  disabled={!permit.delete_row}
                                  onClick={(event) => {
                                    handleDelete(
                                      row.id,
                                      row.material_name,
                                      "Deleted"
                                    );
                                    setMaterial({
                                      ...material,
                                      quantity: 0,
                                    });
                                  }}
                                  size="small"
                                  variant="text"
                                  sx={{
                                    width: 100,
                                    color: "white",
                                    textTransform: "none",
                                    backgroundColor: "red",
                                  }}
                                >
                                  <Tooltip
                                    title={`Delete ${row.material_name}`}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <DeleteOutlineIcon />
                                      <Typography>Delete</Typography>
                                    </Box>
                                  </Tooltip>
                                </Button>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  width: 100,
                                  backgroundColor: "#cf4b4b",
                                  borderRadius: "0px 3px 3px 0px",
                                }}
                              >
                                <Button
                                  disabled={!permit.update_row}
                                  onClick={(event) => {
                                    handleDelete(
                                      row.id,
                                      row.material_name,
                                      "Good"
                                    );
                                    setMaterial({
                                      ...material,
                                      quantity: row.quantity,
                                    });
                                  }}
                                  size="small"
                                  variant="text"
                                  sx={{
                                    width: 100,
                                    color: "white",
                                    textTransform: "none",
                                    backgroundColor: "red",
                                  }}
                                >
                                  <Tooltip
                                    title={`Restore ${row.material_name}`}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 1,
                                      }}
                                    >
                                      <RestoreOutlinedIcon />
                                      <Typography>Restore</Typography>
                                    </Box>
                                  </Tooltip>
                                </Button>
                              </Box>
                            )}
                          </ButtonGroup>
                        )}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Typography sx={{ m: 3 }}>No material found</Typography>
        )}
      </TableContainer>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Updating ...
          </Typography>
        </Box>
      </Backdrop>
    </Paper>
  );
}
