import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import AppsIcon from "@mui/icons-material/Apps";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import dayjs from "dayjs";
import { Button, Stack } from "@mui/material";
import useUpdate from "../../hook/useUpdate";
import useFetch from "../../hook/useFetch";
import Receipt from "../ProcurementAndSales/receipt";

const EditableCell = ({ row, edit, field, align, onBlur }) => (
  <TableCell
    onClick={(e) =>
      row.id === edit
        ? (e.target.contentEditable = true)
        : (e.target.contentEditable = false)
    }
    onBlur={(e) => onBlur(e.target.textContent)}
    align={align}
    sx={{ whiteSpace: "nowrap" }}
  >
    {row[field]}
  </TableCell>
);
//Component for table head
function EnhancedTableHead({ onSelectAllClick, numSelected, rowCount }) {
  return (
    <TableHead
      sx={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}
    >
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {[
          "No",
          "Transaction id",
          "Product Name",
          "Product ID",
          "Category",
          "Quantity",
          "Unit Price",
          "Total Price",
          "Payment Method",
          "Customer Name",
          "Customer Contact",
          "Date of sale",
          "Last updated",
          "Invoice Number",
          "Batch Number",
          "Receipt",
          "Notes",
        ].map((headCell) => (
          <TableCell
            key={headCell}
            sx={{ fontWeight: "bold", whiteSpace: "nowrap" }}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

//Component for table toolbar
function EnhancedTableToolbar() {
  // Common styles
  const toolbarStyles = {
    width: "100%",
    display: "flex",
    alignContent: "center",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #235af3",
    position: "sticky",
    left: 0,
    p: 2,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
          ml: -1,
        }}
      >
        <AppsIcon sx={{ cursor: "pointer" }} />
        <Typography sx={{ color: "black" }}>Recent sales</Typography>
      </Box>
    </Stack>
  );
}

//Collective component to be exported
export default function RecentSales() {
  const tableContainerHeight = window.innerHeight;
  const [selected, setSelected] = useState([]);
  const [edit, setEdit] = useState(0);
  const [date_start, setDateStart] = useState(null);
  const [date_end, setDateEnd] = useState(null);
  const [rows, setRows] = useState([]);
  const [product, setProduct] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [receipt, setReceipt] = useState(false);
  const [comp, setComp] = useState("");
  const token = sessionStorage.getItem("token");
  const url = "https://gp.maflink.com/api/api/sales-transactions/" + edit;

  const fetchUrl = `https://gp.maflink.com/api/api/sales-transactions?page=${page}&search=${encodeURIComponent(
    search
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const { loading, error, confirmation, update } = useUpdate(url);

  const [data, fetchData] = useFetch(fetchUrl);

  useEffect(() => {
    data && setRows(data.data);
  }, [data]);

  const handleDateFilter = (date_start, date_end) => {
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : null;
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : null;

    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  function handleSearch(s) {
    setSearch(s);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.no);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  function handleReceipt(data) {
    setReceipt(!receipt);
    setComp(data);
  }

  const handleClick = (event, no) => {
    const selectedIndex = selected.indexOf(no);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, no);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const isSelected = (no) => selected.indexOf(no) !== -1;

  return (
    <Paper sx={{ width: "100%" }}>
      {receipt && <Receipt data={comp} />}
      <EnhancedTableToolbar
        numSelected={selected.length}
        handleSearch={handleSearch}
        rows={rows}
        handleDateFilter={handleDateFilter}
      />
      <TableContainer
        sx={{
          height: `calc(${tableContainerHeight}px - 240px)`,
          overflowY: "scroll",
        }}
      >
        <Table
          sx={{ minWidth: 750, overflowY: "scroll" }}
          aria-labelledby="tableTitle"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              return (
                <TableRow
                  hover={row.availability === "available"}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  sx={{
                    cursor:
                      row.availability === "available" && edit && "pointer",
                    backgroundColor:
                      row.availability !== "available" && "#ffebc1",
                  }}
                >
                  <TableCell
                    padding="checkbox"
                    onClick={(event) => handleClick(event, row.id)}
                  >
                    <Checkbox color="primary" checked={isItemSelected} />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="transaction_id"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, transaction_id: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_name"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_name: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_id"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_id: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="product_category"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, product_category: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="quantity"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, quantity: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="unit_price"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, unit_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="total_price"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, total_price: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    // edit={edit}
                    field="payment_method"
                    field2={
                      row.payment_method === "Transfer" && (
                        <>
                          {row.bank_name} <b>| </b>
                          {row.txn}
                        </>
                      )
                    }
                    field3={
                      row.payment_method === "Check" && (
                        <>
                          <b>Due date: </b> {row.due_date}
                        </>
                      )
                    }
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, payment_method: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="customer_name"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, customer_name: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="customer_contact"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, customer_contact: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="sales_date"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, sales_date: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="last_updated"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, last_updated: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="invoice_number"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, invoice_number: content })
                    }
                  />
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="batch_number"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, batch_number: content })
                    }
                  />
                  <TableCell>
                    <Button
                      variant="contained"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexFlow: "row nowrap",
                        cursor: "pointer",
                        textTransform: "none",
                        gap: 1,
                        backgroundColor: "#0030b8",
                      }}
                      onClick={() => handleReceipt(row)}
                    >
                      <PreviewOutlinedIcon />
                      <Typography>Preview</Typography>
                    </Button>
                  </TableCell>
                  <EditableCell
                    row={row}
                    edit={edit}
                    field="notes"
                    align="left"
                    onBlur={(content) =>
                      setProduct({ ...product, notes: content })
                    }
                  />
                  <TableCell
                    align="left"
                    sx={{ whiteSpace: "nowrap" }}
                  ></TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {rows.length === 0 && (
          <Typography sx={{ m: 3 }}>No product found</Typography>
        )}
      </TableContainer>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={data && data.current_page !== page}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!data}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Loading ...
          </Typography>
        </Box>
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="h6" sx={{ mt: 2 }}>
            Updating ...
          </Typography>
        </Box>
      </Backdrop>
    </Paper>
  );
}
