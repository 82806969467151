import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ListItemText from "@mui/material/ListItemText";
import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Button from "@mui/material/Button";
import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import ConstructionIcon from "@mui/icons-material/Construction";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import RoofingIcon from "@mui/icons-material/Roofing";
import BugReportIcon from "@mui/icons-material/BugReport";
import LocalActivityOutlinedIcon from "@mui/icons-material/LocalActivityOutlined";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LogoutConfirmation from "../logoutConfirmation";
import Projects from "../projectManagement/projects";
import NewProject from "../projectManagement/newProject";
import ProjectIssue from "../projectManagement/projectIssue";
import MaterialTransaction from "../equipmentAndMaterials/materials/transaction/materialTransaction";
import EquipmentTransaction from "../equipmentAndMaterials/equipments/transaction/equipmentTransaction";

const drawerWidth = 270;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(0),
    display: open && "none",
    flexDirection: "column",
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(3),
    display: open && "flex",
    flexDirection: "column",
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  backgroundColor: " #0030b8",
}));

function Logout() {
  const [logout, setLogout] = React.useState(false);

  const handleLogout = () => {
    setLogout(!logout);
  };
  return (
    <Button
      onClick={handleLogout}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        textTransform: "none",
        color: "black",
        color: "#3f3f3fd6",
      }}
    >
      {logout && <LogoutConfirmation />}
      <LogoutIcon fontSize="small" />
      <Typography>Logout</Typography>
    </Button>
  );
}

function Alert() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
        }}
      >
        <Badge badgeContent={3} color="primary">
          <NotificationsNoneIcon />
        </Badge>
        <Typography>Alert</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
            mt: -1,
            p: 2,
          }}
        >
          Important alerts
        </Box>
        <MenuItem onClick={handleClose}>No alerts yet</MenuItem>
      </Menu>
    </div>
  );
}

function Settings() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
        }}
      >
        <SettingsOutlinedIcon fontSize="small" />
        <Typography>Settings</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
            mt: -1,
            p: 2,
          }}
        >
          Important settings
        </Box>
        <MenuItem onClick={handleClose}>No settings yet</MenuItem>
      </Menu>
    </div>
  );
}

//Dropdown list view

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #0030b8`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

//Nav bar dropdown view component
function NavbarDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { lg: "none", md: "flex", sm: "flex", xs: "flex" } }}>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
      >
        <SegmentOutlinedIcon sx={{ fontSize: "2.25em", color: "#0030b8" }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem>
          <Settings />
        </MenuItem>
        <MenuItem>
          <Alert />
        </MenuItem>
        <MenuItem>
          <Logout />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function ProjectManager() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 1100 ? true : false
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <CssBaseline />
      <Drawer
        sx={{
          width: open && drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#0030b8",
            color: "white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              backgroundColor: " #0030b8",
              pt: 3,
              pl: 1,
              position: "fixed",
              top: 0,
              zIndex: 1,
            }}
          >
            <Link href="/">
              <img
                style={{
                  width: 200,
                  height: "auto",
                  backgroundColor: "white",
                  border: "3px solid white",
                  borderRadius: 3,
                }}
                src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
              />
            </Link>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" && (
                <ArrowBackRoundedIcon
                  sx={{
                    color: "white",
                  }}
                />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <Divider />
        <List sx={{ mt: 3, backgroundColor: " #0030b8" }}>
          <ListItemButton href="/project-management/projects" sx={{ ml: 1 }}>
            <ListItemIcon sx={{ color: "white" }}>
              <RoofingIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Projects</ListItemText>
          </ListItemButton>
          <ListItemButton href="/project-management/new-project" sx={{ ml: 1 }}>
            <ListItemIcon sx={{ color: "white" }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>New project</ListItemText>
          </ListItemButton>
          <ListItemButton
            href="/project-management/project-issues"
            sx={{ ml: 1 }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Project issues</ListItemText>
          </ListItemButton>
          <ListItemButton
            disabled
            href="/project-management/project-activities"
            sx={{ ml: 1 }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <LocalActivityOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Project activities</ListItemText>
          </ListItemButton>
          <ListItemButton
            href="/equipments-material/transaction/material"
            sx={{ ml: 1 }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <FormatColorFillIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Material</ListItemText>
          </ListItemButton>
          <ListItemButton
            href="/equipments-material/transaction/equipment"
            sx={{ ml: 1 }}
          >
            <ListItemIcon sx={{ color: "white" }}>
              <ConstructionIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Equipments</ListItemText>
          </ListItemButton>
          <ListItemButton href="/project-management/report" sx={{ ml: 1 }}>
            <ListItemIcon sx={{ color: "white" }}>
              <AssessmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Report</ListItemText>
          </ListItemButton>
        </List>
      </Drawer>
      <Main open={open}>
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 9,
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FBFBFB",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!open && (
              <>
                <MenuIcon
                  sx={{ fontSize: "2.25em", color: "#0030b8" }}
                  onClick={handleDrawerOpen}
                />
                <Link href="/" underline="none">
                  <img
                    style={{
                      width: "min(200px,100%)",
                      height: "auto",
                      backgroundColor: "white",
                      border: "3px solid white",
                      borderRadius: 3,
                    }}
                    src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
                  />
                </Link>
              </>
            )}
            <Typography
              variant="h6"
              sx={{
                display: { lg: "flex", md: "none", sm: "none", xs: "none" },
                textWrap: "nowrap",
                pr: 2,
              }}
            >
              Project Management
            </Typography>
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            {" "}
            <Logout />
            <Divider orientation="vertical" flexItem />
            <Settings />
            <Divider orientation="vertical" flexItem />
            <Alert />
          </Box>
          <NavbarDropdown />
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Projects />} />
              <Route
                path="/project-management/projects"
                element={<Projects />}
              />
              <Route
                path="/equipments-material/transaction/equipment"
                element={<EquipmentTransaction />}
              />
              <Route
                path="/equipments-material/transaction/material"
                element={<MaterialTransaction />}
              />
              <Route
                path="/project-management/new-project"
                element={<NewProject />}
              />
              <Route
                path="/project-management/project-issues"
                element={<ProjectIssue />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </Main>
    </Box>
  );
}
