import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Button from "@mui/material/Button";
import { Badge, ListItemButton, ListItemIcon } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import SegmentOutlinedIcon from "@mui/icons-material/SegmentOutlined";
import { Link } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MinimizeOutlinedIcon from "@mui/icons-material/MinimizeOutlined";
import AddIcon from "@mui/icons-material/Add";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ConstructionIcon from "@mui/icons-material/Construction";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Products from "../ProcurementAndSales/product";
import AddProduct from "../ProcurementAndSales/addProduct";
import Users from "../userManagement/user";
import AddUser from "../userManagement/addUser";
import LogoutConfirmation from "../logoutConfirmation";
import PurchaseTransaction from "../ProcurementAndSales/transaction.js/purchaseTransaction";
import SalesTransaction from "../ProcurementAndSales/transaction.js/salesTransaction";
import OrderTransaction from "../ProcurementAndSales/transaction.js/orderTaransaction";
import Sales from "../ProcurementAndSales/sales";
import Orders from "../ProcurementAndSales/order";
import NewSales from "../ProcurementAndSales/newSales";
import NewOrder from "../ProcurementAndSales/newOrder";
import Projects from "../projectManagement/projects";
import NewProject from "../projectManagement/newProject";
import ProjectIssue from "../projectManagement/projectIssue";
import AllDocuments from "../documentManagement/allDocuments";
import NewDocument from "../documentManagement/newDocument";
import Materials from "../equipmentAndMaterials/materials/material";
import AddMaterial from "../equipmentAndMaterials/materials/addMaterial";
import MaterialTransaction from "../equipmentAndMaterials/materials/transaction/materialTransaction";
import DispatchMaterial from "../equipmentAndMaterials/materials/dispatchMaterial";
import Equipment from "../equipmentAndMaterials/equipments/equipments";
import AddEquipment from "../equipmentAndMaterials/equipments/addEquipment";
import DeployEquipment from "../equipmentAndMaterials/equipments/deployEquipment";
import EquipmentTransaction from "../equipmentAndMaterials/equipments/transaction/equipmentTransaction";
import ReturnEquipment from "../equipmentAndMaterials/equipments/returnEquipment";
import ProcurementAndSalesReport from "../ProcurementAndSales/procurementAndSalesReport";
import Dashboard from "../dashboard/dashboard";

const drawerWidth = 270;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(0),
  [theme.breakpoints.up("xs")]: {
    padding: theme.spacing(0),
    display: open && "none",
    flexDirection: "column",
  },
  padding: theme.spacing(1),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(1),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
    display: open && "flex",
    flexDirection: "column",
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(3),
    display: open && "flex",
    flexDirection: "column",
  },
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - ${drawerWidth}px)`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  backgroundColor: " #0030b8",
}));

function Logout() {
  const [logout, setLogout] = React.useState(false);

  const handleLogout = () => {
    setLogout(!logout);
  };
  return (
    <Button
      onClick={handleLogout}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        textTransform: "none",
        color: "#3f3f3fd6",
      }}
    >
      {logout && <LogoutConfirmation />}
      <LogoutIcon fontSize="small" />
      <Typography>Logout</Typography>
    </Button>
  );
}

function Alert() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
        }}
      >
        <Badge badgeContent={3} color="primary">
          <NotificationsNoneIcon />
        </Badge>
        <Typography>Alert</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
            mt: -1,
            p: 2,
          }}
        >
          Important alerts
        </Box>
        <MenuItem onClick={handleClose}>No alerts yet</MenuItem>
      </Menu>
    </div>
  );
}

function Settings() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
          color: "black",
          color: "#3f3f3fd6",
        }}
      >
        <SettingsOutlinedIcon fontSize="small" />
        <Typography>Settings</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#235af3",
            borderBottom: "2px solid #235af3",
            mt: -1,
            p: 2,
          }}
        >
          Important settings
        </Box>
        <MenuItem onClick={handleClose}>No settings yet</MenuItem>
      </Menu>
    </div>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography sx={{ whiteSpace: "nowrap" }}>Date selector</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}
//Dropdown list view

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid #0030b8`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    // borderBottom: `1px solid #235af3`,
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ManageUsers() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion onChange={handleChange("panel2")}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <PeopleIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>
              Manage users
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton
              disabled={
                !JSON.parse(sessionStorage.getItem("permissions"))
                  .create_new_user
              }
              sx={{ width: 200 }}
            >
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/user-management/add-user"
                >
                  Add user
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/user-management/users"
                >
                  All users
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function DocumentManagement() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <FolderOutlinedIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>
              Document Management
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/document-management/new-document"
                >
                  New document
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/document-management/all-document"
                >
                  All document
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function EquipmentMaterials() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <ConstructionIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>
              Equipment & Materials
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/add-equipment"
                >
                  Add equipment
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/add-material"
                >
                  Add material
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/return-equipment"
                >
                  Return equipment
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/deploy-equipment"
                >
                  Deploy equipment
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/dispatch-material"
                >
                  Dispatch material
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement/purchase-orders"
                >
                  Category
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/equipment"
                >
                  Equipments
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/material"
                >
                  Materials
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
          <Divider
            sx={{ height: 3, backgroundColor: "#7a9dffab", ml: -5, mr: -3 }}
          />
          <EquipmentMaterialTransaction />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

function ProjectManagement() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <ManageAccountsIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>
              Project Management
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/project-management/new-project"
                >
                  New project
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/project-management/projects"
                >
                  Projects
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/project-management/project-issues"
                >
                  Project issues
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton disabled sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/project-management/project-activities"
                >
                  Project activities
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
function EquipmentMaterialTransaction() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{ border: "none" }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#235af3",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Typography sx={{ ml: 1, textAlign: "left", whiteSpace: "nowrap" }}>
              Transaction History
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <AddIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <MinimizeOutlinedIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List>
            <ListItemButton>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/transaction/equipment"
                >
                  Equipments
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/equipments-material/transaction/material"
                >
                  Material
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
function InventoryTransaction() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        sx={{ border: "none" }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#235af3",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Typography sx={{ ml: 1, textAlign: "left", whiteSpace: "nowrap" }}>
              Inventory transaction
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <AddIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <MinimizeOutlinedIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List>
            <ListItemButton>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/transaction/purchase"
                >
                  Products
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/transaction/sales"
                >
                  Sales
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/transaction/order"
                >
                  Reorder
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
function ProcurementSales() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <ShoppingCartOutlinedIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>
              Procurement & Sales
            </Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/add-product"
                >
                  Add product
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/new-sale"
                >
                  New sale
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/new-order"
                >
                  New order
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/product-category"
                >
                  Category
                </Link>
              </ListItemText>
            </ListItemButton>
            <Divider sx={{ backgroundColor: "#7a9dffab", ml: -5, mr: -3 }} />
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/products"
                >
                  Products
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/sales"
                >
                  Sales
                </Link>
              </ListItemText>
            </ListItemButton>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/orders"
                >
                  Reorder
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
          <Divider
            sx={{ height: 3, backgroundColor: "#7a9dffab", ml: -5, mr: -3 }}
          />
          <InventoryTransaction />
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
function Report() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            display: "flex",
            alignItems: "flex-start",
            color: "white",
            backgroundColor: "#0030b8",
          }}
        >
          <AssessmentOutlinedIcon />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ pl: 3, textAlign: "left" }}>Reports</Typography>
            {!expanded ? (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandMoreIcon />
              </ListItemIcon>
            ) : (
              <ListItemIcon sx={{ color: "white" }}>
                <ExpandLessIcon />
              </ListItemIcon>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#235af3",
          }}
        >
          <List sx={{ ml: 3 }}>
            <ListItemButton sx={{ width: 200 }}>
              <ListItemText>
                <Link
                  underline="none"
                  sx={{ color: "white" }}
                  href="/procurement-sales/report"
                >
                  Procurement and sales
                </Link>
              </ListItemText>
            </ListItemButton>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

//Nav bar dropdown view component
function NavbarDropdown({ handleDateFilter }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleDropDown = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },
      }}
    >
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleDropDown}
      >
        <SegmentOutlinedIcon
          sx={{
            fontSize: "2.25em",
            color: "#0030b8",
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
        sx={{
          ml: -3,
        }}
      >
        {/* <MenuItem>
          <DateFilter handleDateFilter={handleDateFilter} />
        </MenuItem> */}
        <MenuItem>
          <Settings />
        </MenuItem>
        <MenuItem>
          <Alert />
        </MenuItem>
        <MenuItem>
          <Logout />
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function Admin() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth > 1100 ? true : false
  );
  const [dateSelector, setDateSelector] = React.useState(false);

  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);

  const [date_start, setDateStart] = useState(
    dayjs("2000-01-01").format("YYYY-MM-DD")
  );
  const [date_end, setDateEnd] = useState(dayjs().format("YYYY-MM-DD"));

  const handleDateFilter = (date_start, date_end) => {
    // Format the start date if it exists, otherwise default to "2000-01-01"
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : dayjs("2000-01-01").format("YYYY-MM-DD");

    // Format the end date if it exists, otherwise default to the current date
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");

    // Update the state with the formatted dates
    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  function handleDateSelector() {
    setDateSelector(true);
  }
  // console.log(date_start);

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
      }}
    >
      <CssBaseline />
      <Drawer
        sx={{
          width: open && drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#0030b8",
            color: "white",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-around",
              backgroundColor: " #0030b8",
              pt: 3,
              pl: 1,
              position: "fixed",
              top: 0,
              zIndex: 1,
            }}
          >
            <Link href="/">
              <img
                style={{
                  width: 200,
                  height: "auto",
                  backgroundColor: "white",
                  border: "3px solid white",
                  borderRadius: 3,
                }}
                src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
              />
            </Link>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" && (
                <ArrowBackRoundedIcon
                  sx={{
                    color: "white",
                  }}
                />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <Divider />
        <List sx={{ mt: 3, backgroundColor: " #0030b8" }}>
          <ListItemButton href="/" sx={{ ml: 1 }}>
            <ListItemIcon sx={{ color: "white" }}>
              <DashboardOutlinedIcon />
            </ListItemIcon>
            <ListItemText sx={{ ml: -1 }}>Dashboard</ListItemText>
          </ListItemButton>
          <ManageUsers />
          <DocumentManagement />
          <EquipmentMaterials />
          <ProjectManagement />
          <ProcurementSales />
          <Report />
        </List>
      </Drawer>
      <Main open={open}>
        <Box
          sx={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#FBFBFB",
            cursor: "pointer",
            pt: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!open && (
              <>
                <MenuIcon
                  sx={{
                    fontSize: "2.25em",
                    color: "#0030b8",
                  }}
                  onClick={handleDrawerOpen}
                />
                <Link href="/" underline="none">
                  <img
                    style={{
                      width: "min(200px, 100%)",
                      height: "auto",
                      backgroundColor: "white",
                      border: "3px solid white",
                      borderRadius: 3,
                    }}
                    src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
                  />
                </Link>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "flex" },
              flexFlow: "row nowrap",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 3,
            }}
          >
            {dateSelector && <DateFilter handleDateFilter={handleDateFilter} />}
            <Logout />
            <Divider orientation="vertical" flexItem />
            <Settings />
            <Divider orientation="vertical" flexItem />
            <Alert />
          </Box>
          <NavbarDropdown handleDateFilter={handleDateFilter} />
        </Box>
        <Divider />
        <Box
          sx={{
            mt: 3,
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "center",
          }}
        >
          <BrowserRouter>
            <Routes>
              <Route
                path="/"
                element={
                  <Dashboard
                    date_start={date_start}
                    date_end={date_end}
                    handleDateSelector={handleDateSelector}
                  />
                }
              />
              <Route
                path="/equipments-material/equipment"
                element={<Equipment />}
              />
              <Route
                path="/equipments-material/add-equipment"
                element={<AddEquipment />}
              />
              <Route
                path="/equipments-material/deploy-equipment"
                element={<DeployEquipment />}
              />
              <Route
                path="/equipments-material/return-equipment"
                element={<ReturnEquipment />}
              />
              <Route
                path="/equipments-material/material"
                element={<Materials />}
              />
              <Route
                path="/equipments-material/add-material"
                element={<AddMaterial />}
              />
              <Route
                path="/equipments-material/dispatch-material"
                element={<DispatchMaterial />}
              />
              <Route
                path="/equipments-material/transaction/equipment"
                element={<EquipmentTransaction />}
              />
              <Route
                path="/equipments-material/transaction/material"
                element={<MaterialTransaction />}
              />
              <Route
                path="/document-management/all-document"
                element={<AllDocuments />}
              />
              <Route
                path="/document-management/new-document"
                element={<NewDocument />}
              />
              <Route
                path="/project-management/projects"
                element={<Projects />}
              />
              <Route
                path="/project-management/new-project"
                element={<NewProject />}
              />
              <Route
                path="/project-management/project-issues"
                element={<ProjectIssue />}
              />
              <Route
                path="/procurement-sales/products"
                element={<Products />}
              />
              <Route path="/procurement-sales/sales" element={<Sales />} />
              <Route path="/procurement-sales/orders" element={<Orders />} />
              <Route
                path="/procurement-sales/add-product"
                element={<AddProduct />}
              />
              <Route
                path="/procurement-sales/new-sale"
                element={<NewSales />}
              />
              <Route
                path="/procurement-sales/new-order"
                element={<NewOrder />}
              />
              <Route path="/user-management/users" element={<Users />} />
              <Route path="/user-management/add-user" element={<AddUser />} />
              <Route
                path="/procurement-sales/transaction/purchase"
                element={<PurchaseTransaction />}
              />
              <Route
                path="/procurement-sales/transaction/sales"
                element={<SalesTransaction />}
              />
              <Route
                path="/procurement-sales/transaction/order"
                element={<OrderTransaction />}
              />
              <Route
                path="/procurement-sales/report"
                element={<ProcurementAndSalesReport />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </BrowserRouter>
        </Box>
      </Main>
    </Box>
  );
}
