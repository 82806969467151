import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Button, Stack } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import useFetch from "../../hook/useFetch";
import ExportToPdf from "../exportToPdf";

//Dropdown view component
function Dropdown() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        textTransform: "none",
        color: "black",
        color: "#3f3f3fd6",
        ml: -1,
      }}
    >
      <AssessmentOutlinedIcon />
      <Typography sx={{ color: "black" }}>
        Procurement and sales report
      </Typography>
    </Box>
  );
}

function DateFilter({ handleDateFilter }) {
  const [fromValue, setFromValue] = React.useState(null);
  const [toValue, setToValue] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleDateFilter(fromValue, toValue);
  }, [fromValue, toValue]);

  return (
    <div>
      <Button
        variant="text"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          textTransform: "none",
        }}
      >
        <FilterListOutlinedIcon fontSize="small" />
        <Typography>Date filter</Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <Box sx={{ m: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="From"
                value={fromValue}
                onChange={(newValue) => setFromValue(newValue)}
              />
              <DatePicker
                label="To"
                value={toValue}
                onChange={(newValue) => setToValue(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
      </Menu>
    </div>
  );
}

function ReportBody({
  date_start,
  date_end,
  products = [],
  sales = [],
  reorders = [],
}) {
  return (
    <TableContainer sx={{ mt: 3 }}>
      <Box
        sx={{
          width: "90%",
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "space-between",
          alignItems: "center",
          p: 5,
          gap: 3,
        }}
      >
        <img
          style={{
            width: 300,
            height: "auto",
            backgroundColor: "white",
            border: "3px solid white",
            borderRadius: 3,
          }}
          src="https://generalpowerplc.com/wp-content/uploads/2019/08/PNG-1.png?189db0&189db0"
        />
        <Typography variant="h5" sx={{ textDecoration: "underline" }}>
          Report
        </Typography>

        <Typography>
          Date:{" "}
          {date_start && date_end
            ? `${date_start} - ${date_end}`
            : date_start
            ? date_start + " -- "
            : date_end
            ? " -- " + date_end
            : "All"}
        </Typography>
      </Box>
      <Box sx={{ width: "95%", pb: 7 }}>
        <Divider textAlign="left">
          <Chip label="Purchases" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {products.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {product.product_name}
                  </TableCell>
                  <TableCell sx={{ borderBottom: "none" }}>
                    {product.product_description}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {product.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product.unit_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(product.total_price)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    products.reduce(
                      (acc, product) => acc + parseFloat(product.total_price),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No products</Typography>
        )}
      </Box>
      <Box sx={{ width: "95%", pb: 7 }}>
        <Divider textAlign="left">
          <Chip label="Sales" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {sales.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Tax
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Withholding tax
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sales.map((sale, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {sale.product_name}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {sale.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.unit_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.total_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.tax)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(sale.withholding_tax)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce(
                      (acc, sale) => acc + parseFloat(sale.total_price),
                      0
                    )
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce((acc, sale) => acc + parseFloat(sale.tax), 0)
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    sales.reduce(
                      (acc, sale) => acc + parseFloat(sale.withholding_tax),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No sales</Typography>
        )}
      </Box>
      <Box sx={{ width: "95%", pb: 7 }}>
        <Divider textAlign="left">
          <Chip label="Reorders" size="small" sx={{ fontWeight: "bold" }} />
        </Divider>
        {reorders.length > 0 ? (
          <Table sx={{ m: 3 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Item
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Quantity
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Unit price
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: "1.05em",
                  }}
                >
                  Total price
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reorders.map((reorder, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: "none" },
                  }}
                >
                  <TableCell sx={{ borderBottom: "none" }}>
                    {reorder.product_name}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {reorder.quantity}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(reorder.unit_price)}
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: "none" }}>
                    {new Intl.NumberFormat("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(reorder.total_price)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: "bold",
                    borderTop: "1px solid",
                    textWrap: "nowrap",
                  }}
                >
                  Grand Total
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", borderTop: "1px solid" }}
                >
                  {new Intl.NumberFormat("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(
                    reorders.reduce(
                      (acc, reorder) => acc + parseFloat(reorder.total_price),
                      0
                    )
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          <Typography sx={{ p: 3 }}>No orders</Typography>
        )}
      </Box>
    </TableContainer>
  );
}
//Component for table toolbar
function EnhancedTableToolbar({ handleDateFilter, Comp }) {
  // Common styles
  const toolbarStyles = {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    borderBottom: "2px solid #235af3",
    position: "sticky",
    gap: 2,
    left: 0,
    p: 2,
  };

  return (
    <Stack sx={toolbarStyles}>
      <Dropdown />
      <DateFilter handleDateFilter={handleDateFilter} />
      <ExportToPdf Comp={Comp} />
    </Stack>
  );
}

//Collective component to be exported
export default function ProcurementAndSalesReport() {
  const [products, setProducts] = useState([]);
  const [sales, setSales] = useState([]);
  const [reorders, setReorders] = useState([]);
  const [date_start, setDateStart] = useState(
    dayjs("2000-01-01").format("YYYY-MM-DD")
  );
  const [date_end, setDateEnd] = useState(dayjs().format("YYYY-MM-DD"));
  const token = sessionStorage.getItem("token");

  const productUrl = `https://gp.maflink.com/api/api/products?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const salesUrl = `https://gp.maflink.com/api/api/sales?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const reordersUrl = `https://gp.maflink.com/api/api/orders?page=${1}&search=${encodeURIComponent(
    ""
  )}&date_start=${encodeURIComponent(date_start)}&date_end=${encodeURIComponent(
    date_end
  )}`;

  const [productData, fetchProduct] = useFetch(productUrl);
  const [salesData, fetchSales] = useFetch(salesUrl);
  const [reordersData, fetchReorders] = useFetch(reordersUrl);

  useEffect(() => {
    productData && setProducts(productData);
    salesData && setSales(salesData);
    reordersData && setReorders(reordersData);
  }, [productData, salesData, reordersData]);

  const handleDateFilter = (date_start, date_end) => {
    // Format the start date if it exists, otherwise default to "2000-01-01"
    const formattedStartDate = date_start
      ? dayjs(date_start).format("YYYY-MM-DD")
      : dayjs("2000-01-01").format("YYYY-MM-DD");

    // Format the end date if it exists, otherwise default to the current date
    const formattedEndDate = date_end
      ? dayjs(date_end).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD");

    // Update the state with the formatted dates
    setDateStart(formattedStartDate);
    setDateEnd(formattedEndDate);
  };

  return (
    <Paper sx={{ width: "min(1000px,100%)" }}>
      <EnhancedTableToolbar
        handleDateFilter={handleDateFilter}
        Comp={(props) => (
          <ReportBody
            {...props}
            date_start={date_start}
            date_end={date_end}
            products={products}
            sales={sales}
            reorders={reorders}
          />
        )}
      />
      <ReportBody
        date_start={date_start}
        date_end={date_end}
        products={products}
        sales={sales}
        reorders={reorders}
      />
    </Paper>
  );
}
